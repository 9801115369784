import { makeStyles, shorthands,Text } from "@fluentui/react-components";
import * as React from "react";
import { LearnerSyllabusTask } from "../../models";
import { LiveSessionCard } from "./LiveSessionCard";
import { useState } from "react";
import { getLearnerSyllabusTasks } from "../../services/blendedLearningService";
import { useQuery } from "@tanstack/react-query";
import { BlendedLearningContext } from "../../internal/singletonContext";

const useStyles = makeStyles({
  cardsWrapper: {
    display: "flex",
    ...shorthands.gap("1em"),
    alignContent: "space-between",
    flexWrap: "wrap",
  },
  bodyNoMoresessions: {
    height: "10em",
    marginTop: "7.5em",
  },
  bodyNoMoresessionsText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    marginLeft: "22.5em",
  },
});

/**
 * LiveSessionCards component
 */
interface IComponentProps {
  channelId?: string;
  learnerId: string,
  deliveryId: string,
}

export const LiveSessionCards: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { channelId, learnerId, deliveryId } = props;
  const [items, setItems] = useState<LearnerSyllabusTask[]>([]);

  const { data: tasksData, isLoading: isTasksDataLoading } = useQuery({
    queryKey: ["UserTasksData"],
    queryFn: () => getLearnerSyllabusTasks(learnerId, deliveryId, BlendedLearningContext.getInstance().appConfig!),
  });

  React.useEffect(() => {
    if (!isTasksDataLoading) {
      const data = tasksData != null && channelId != undefined ? tasksData.filter((x: LearnerSyllabusTask) => x.deliveryTopic.teamChannelId == channelId &&
      (
        x.syllabusTask.platform.name=== "Microsoft Teams" && 
        (x.syllabusTask.syllabusTaskType.name==="Lab Sessions" 
      || x.syllabusTask.syllabusTaskType.name==="Chalk Talks"
      || x.syllabusTask.syllabusTaskType.name==="Office Hours"
      ))) : [];
      setItems(data);
    }
  }, [tasksData, isTasksDataLoading]);


  const classes = useStyles();

  return (
    <>
    {items != null && items.length > 0 ?
     (<span className={classes.cardsWrapper}>
        {items.map((item: any, index) => (
          <LiveSessionCard item={item} />
        ))}
      </span>) :
      (<div className={classes.bodyNoMoresessions}>
         <Text className={classes.bodyNoMoresessionsText}>No more live sessions for this course.</Text></div>)
        }
     </>
  );
};

export default LiveSessionCards;
