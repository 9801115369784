import { Body1, Button, Link, Spinner, Text } from "@fluentui/react-components";
import { Widget } from "../lib/Widget";

import { ArrowRight16Filled, Megaphone20Regular } from "@fluentui/react-icons";

import { widgetStyle } from "../lib/Widget.styles";
import { UserInfo } from "@microsoft/teamsfx";
import { BlendedLearningContext } from "../../internal/singletonContext";
import IntroDialog from "../components/IntroDialog";
import {
  getHomeOverview,
  getLearnerWelcomeTourState,
} from "../../services/blendedLearningService";
import { Delivery, HomeOverview, IDeliveryTopic } from "../../models";
import Helper from "../../common/helper";
import { getTopicsByDeliveryId } from "../../services/deliveryService";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";

import "../styles/Common.css";
import "../styles/HomeOverview.scss";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { pages } from "@microsoft/teams-js";


interface IHomeOverview {
  user?: UserInfo;
  currentWeekNumber: number;
  isLastWeek: boolean;
  isClosed: boolean;
  homeOverviews: HomeOverview[];
  deliveryDetails: Delivery;
  eventStartDate: string;
  eventEndDate: string;
  weekDescription: string;
  topics?: IDeliveryTopic;
  learnerId: string;
  isLearnerWelcomeTourCompleted: boolean;
}

export class HomeOverviewWidget extends Widget<IHomeOverview> {
  protected async getData(): Promise<IHomeOverview> {
    const blxpMainData =
      await BlendedLearningContext.getInstance().getBlendedLearning();
    let homeOverviews = await getHomeOverview(blxpMainData.appConfig);

    // let currentDelivery = blxpMainData.delivery;
    let weekNumber = Helper.getCurrentWeekForBL(
      new Date(blxpMainData.delivery?.startDate),
      new Date(blxpMainData.delivery?.endDate),
      new Date()
    );
    let weekDescription =
      homeOverviews?.find((x) => x?.weekNumber === weekNumber)?.description ??
      "";
    let topics = await getTopicsByDeliveryId(blxpMainData.delivery?.id, "", blxpMainData.appConfig);    
    let cuurentTopic = topics?.find((x) => x?.weekNumber === weekNumber);
    let learnerWelcomeTourStatus = await getLearnerWelcomeTourState(
      blxpMainData.delivery?.id,
      blxpMainData?.learner?.id, blxpMainData.appConfig
    );

    return {
      currentWeekNumber: weekNumber,
      isLastWeek: false,
      isClosed: learnerWelcomeTourStatus?.isWelcomeTourCompleted,
      homeOverviews: homeOverviews,
      deliveryDetails: blxpMainData.delivery,
      eventStartDate: blxpMainData.delivery?.startDate,
      eventEndDate: blxpMainData.delivery?.endDate,
      weekDescription: weekDescription,
      topics: cuurentTopic,
      learnerId: blxpMainData.learner?.id,
      isLearnerWelcomeTourCompleted: learnerWelcomeTourStatus?.isWelcomeTourCompleted,
    } as IHomeOverview;
  }

  protected headerContent(): JSX.Element | undefined {
    return (
      <div key="div-chart-header" className={widgetStyle.headerContent}>
        <div className={widgetStyle.purpleIcon}>
          <Megaphone20Regular
            aria-label="Megaphone icon"
            className={widgetStyle.iconSmall}
          />
        </div>
        <Text
          key="text-chart-title"
          className={widgetStyle.headerText}
          as="h2"
          aria-labelledby="text"
        >
          {
            this.state.currentWeekNumber === 0 &&
              `Welcome to ${this.state.deliveryDetails?.course?.name}!`
            // <FormattedMessage defaultMessage="Welcome to {catalogItem}!" values={{ catalogItem: courseName }} />
          }
          {this.state.currentWeekNumber !== 0 &&
            this.state.currentWeekNumber !== 5 && (
              <>
                Focus Area:{" "}
                <Button
                  appearance="transparent"
                  aria-label={`${this.state.topics?.topics?.name} button`}
                  className={widgetStyle.currentTopicButton}
                  onClick={() => this.NavigateToFocusAreaTab()}
                >
                  {this.state.topics?.topics?.name}
                </Button>
              </>
            )}
          {this.state.currentWeekNumber === 5 &&
            `Congratulations! Your ${this.state.deliveryDetails?.course?.name} course is complete!`}
        </Text>
      </div>
    );
  }

  protected bodyContent(): JSX.Element | undefined {
    let updatedWeekDescription = this.state.weekDescription.includes(
      "<last date of course>",
      0
    )
      ? this.state.weekDescription.replace(
          "<last date of course>",
          new Date(this.state.eventEndDate).toDateString()
        )
      : this.state.weekDescription;
    return (
      <>
        <Body1>
          <div
            className={widgetStyle.body1Style}
            dangerouslySetInnerHTML={{ __html: updatedWeekDescription }}
          ></div>
        </Body1>
      </>
    );
  }
  protected loadingContent(): JSX.Element | undefined {
    return (
      <div className="loading-layout">
        <Spinner label="Loading..." labelPosition="below" />
      </div>
    );
  }

  protected footerContent(): JSX.Element | undefined {
    const lastWeek = false;
    const introMessage = "Welcome";

    return (
      <div>
        <Button
          key="bt-chart-footer"
          appearance="transparent"
          icon={<ArrowRight16Filled />}
          iconPosition="after"
          size="small"
          className={widgetStyle.footerBtn}
          onClick={() => {
            this.openDialog();
          }} // navigate to detailed page
        >
          <Link className={widgetStyle.linkStyle}>
          Launch Welcome Tour
          </Link>
        </Button>
        <IntroDialog
          isOpen={!this.state.isClosed}
          onDismiss={() => {
            this.onDismiss();
          }}
          textToDisplay={introMessage}
          learnerId={this.state.learnerId}
          deliveryId={this.state.deliveryDetails?.id}
          onWelcomeTourComplete={() => {
            this.onWelcomeTourComplete();
          }}
        />
      </div>
    );
  }

  private onWelcomeTourComplete = () => {
    this.setState({ isLearnerWelcomeTourCompleted: true });
  };
  private openDialog = () => {
    this.setState({
      isClosed: false,
    });
  };

  private onDismiss = () => {
    this.setState({
      isClosed: true,
    });
  };
  protected async NavigateToFocusAreaTab(): Promise<void> {
    const tabInstances = await pages.tabs.getTabInstances({
      favoriteChannelsOnly: false,
      favoriteTeamsOnly: false,
    });
    const focusAreatabInstance = tabInstances.teamTabs.filter(
      (x) => x.channelId == this.state.topics?.teamChannelId
    );
    if (focusAreatabInstance != null && focusAreatabInstance.length > 0) {
      await pages.tabs.navigateToTab(focusAreatabInstance[0]);
    }
  }
}

export default withAITracking(ai.reactPlugin,HomeOverviewWidget);
