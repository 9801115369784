import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  Text,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Checkbox,
  Spinner,
  Button,
  makeStyles,
  mergeClasses,
  Tooltip,
  tokens,
} from "@fluentui/react-components";
import {
  Flag24Regular,
  Flag24Filled,
  ChevronDown24Filled,
  ChevronUp24Filled,
} from "@fluentui/react-icons";
import * as React from "react";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Learner, LearnerSyllabusTask, mediaAccessResult } from "../../models";
import { getLearnerSyllabusTasks } from "../../services/blendedLearningService";
import {
  updateLearnerSyllabusTaskCompletedState,
  updateLearnerSyllabusTaskFlaggedState,
} from "../../services/syllabusService";
import CustomIcon from "./CustomIcon";
import MoreInfo from "./MoreInfo";
import ActionLabel from "./ActionLabel";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { BlendedLearningContext } from "../../internal/singletonContext";
import JoinSessionButton from "./JoinSessionButton";
import { ClarityHelper } from "../../internal/ClarityHelper";
import DurationTimeLabel from "./DurationTimeLabel";
import { SendInviteDialog } from "./SendInviteDialog";
import { getFormattedTaskOrderNumber } from "../../common/formatTask";

import "../styles/Syllabus.scss";
import { convertToLocalTimeZone } from "../../common/dateUtils";
import mediaAccessContext from "../../internal/mediaAccessContext";
import { getMediaSASToken } from "../../services/mediaServices";
import { PdfDocument } from "./PdfDocument";
import { validateRegistration } from "../../services/learnerService";


type Item = LearnerSyllabusTask;

/**
 * SyllabusTable component
 */
interface IComponentProps {
  isShowSelectedFousArea: boolean;
  selectedFilters: string[];
  selectedFocusArea: string;
  learner: Learner;
  deliveryId: string;
  onDataChange: () => void;
}

const useStyles = makeStyles({
  textAligned: {
    position: "relative",
    bottom: "0.4em",
    left: "0.5em",
  },
  buttonStyles: {
    color: tokens.colorCompoundBrandForeground1Hover,
    fontWeight: "bold",
    marginLeft: "1em",
  },
  positionTop: {
    position: "absolute",
    top: "0.5em",
  },
  smCol: {
    width: "0.5em",
  },
  mdCol: {
    width: "2em",
  },
  lgCol: {
    width: "3em",
  },
  xlCol: {
    width: "7em",
    whiteSpace: "nowrap",
  },
  fullWidth: {
    display: "block",
    width: "100%",
    paddingTop: "0.5em",
    paddingBottom: "0.75em",
  },
  marginTop: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  platformContainer: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    alignItems: "left",
  },
  seeMoreButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1em",
  },
});

export const SyllabusTable: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const {
    isShowSelectedFousArea,
    selectedFilters,
    selectedFocusArea,
    learner,
    deliveryId,
    onDataChange,
  } = props;
  const classes = useStyles();

  const { data: tasksData, isLoading: isTasksDataLoading } = useQuery({
    queryKey: ["UserTasksData"],
    queryFn: () => getLearnerSyllabusTasks(learner.id, deliveryId, BlendedLearningContext.getInstance().appConfig!),
     });   

     const { data: blxpData, isLoading: blxpDataLoading } = useQuery({
    queryKey: ["BlxpData"],
    queryFn: () => BlendedLearningContext.getInstance().getBlendedLearning(),
  });

  const handleClickContinue = () => {
    ClarityHelper.setCustomTag("SyllabusTable", "JoinSessionClicked");
    ai.appInsights?.trackEvent({
      name: "Send invites from Syllabus table",
    });
  };

  const [items, setItems] = useState<LearnerSyllabusTask[]>([]);
  const [filtereditems, setFilteredItems] = useState<LearnerSyllabusTask[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShowPdfTask, setIsShowPdfTask] = useState(false);  

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isTasksDataLoading) {
      setItems(tasksData);
      setFilteredItems(clone(tasksData)); 
       if ((tasksData == null || tasksData.length == 0)
         && (blxpData?.learner?.learnerId != null && blxpData?.learner?.learnerId != '' && blxpData?.learner?.learnerId != undefined)
       ) {
        validateLearnerRegistration.mutateAsync();
      }    
    }

  }, [tasksData, isTasksDataLoading]);

  useEffect(() => {
    if (
      selectedFilters.length > 0 ||
      (selectedFocusArea !== "" &&
        selectedFocusArea !== undefined &&
        selectedFocusArea !== null)
    ) {
      let tasks = clone(items);
      let syllabusTaskResult = [] as LearnerSyllabusTask[];
      setIsShowPdfTask(false);
      if (
        selectedFocusArea !== "" &&
        selectedFocusArea !== undefined &&
        selectedFocusArea !== null
      ) {
        tasks = tasks.filter((x) => x.deliveryTopic.id == selectedFocusArea);
        syllabusTaskResult = tasks;
        let nextsteptask=items.filter((x) => x.deliveryTopic.topics?.name=="Next Steps");
        if(nextsteptask !=null && nextsteptask.length>0 && nextsteptask[0].deliveryTopic.id==selectedFocusArea)
        {setIsShowPdfTask(true);}
      }
      if (selectedFilters.length > 0) {
        let allTasks = applyAllTaskFilter(tasks, selectedFilters);
        let flageedTasks = applyFlagedTaskFilter(allTasks, selectedFilters);
        let taskTypetasks = applyTaskTypeFilter(flageedTasks, selectedFilters);
        syllabusTaskResult = taskTypetasks;
      } else {
        syllabusTaskResult = tasks;
      }
      setFilteredItems(syllabusTaskResult);
    } else {
      setFilteredItems(clone(items));
    }
    onDataChange();
  }, [selectedFocusArea, selectedFilters, items]);

  const applyAllTaskFilter = (
    tasks: LearnerSyllabusTask[],
    selectedFilters: string[]
  ) => {
    if (selectedFilters.findIndex((x) => x === "Completedtasks") > -1) {
      return tasks.filter((x) => x.isTaskCompleted) as LearnerSyllabusTask[];
    } else if (selectedFilters.findIndex((x) => x === "Remainingtasks") > -1) {
      return tasks.filter((x) => !x.isTaskCompleted) as LearnerSyllabusTask[];
    }
    return tasks;
  };

  const applyFlagedTaskFilter = (
    tasks: LearnerSyllabusTask[],
    selectedFilters: string[]
  ) => {
    if (selectedFilters.findIndex((x) => x === "Flaggedtasks") > -1) {
      return tasks.filter((x) => x.isTaskFlagged) as LearnerSyllabusTask[];
    } else {
      return tasks;
    }
  };

  const applyTaskTypeFilter = (
    tasks: LearnerSyllabusTask[],
    selectedFilters: string[]
  ) => {
    const sylabusTypeTaskFilters = [
      "Videos",
      "Modules",
      "Labs",
      "Chalk Talks",
      "Office Hours",
      "Lab Sessions",
    ];
    if (
      selectedFilters.filter((x) => sylabusTypeTaskFilters.includes(x)).length >
      0
    ) {
      return tasks.filter((x) =>
        selectedFilters.includes(x.syllabusTask.syllabusTaskType.name.trim())
      ) as LearnerSyllabusTask[];
    } else {
      return tasks;
    }
  };

  const taskFlagMutation = useMutation({
    mutationFn: async (row: any) => {
      await updateLearnerSyllabusTaskFlaggedState(row.id, learner.email, blxpData?.appConfig!);
    },
    onMutate: (row: any) => {
      const isTaskFlagged = !row.isTaskFlagged;
      const newItems =
        filtereditems.map((item) => ({
          ...item,
          isTaskFlagged:
            item.id === row.id ? isTaskFlagged : item.isTaskFlagged,
          isTaskFlaggedInProgress:
            item.id === row.id ? true : item.isTaskFlaggedInProgress,
        })) ?? [];
      setFilteredItems(newItems);

      return { isTaskFlagged: isTaskFlagged, row: row };
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["UserTasksData"]);
      const isTaskFlagged = context?.isTaskFlagged ?? false;
      const row = context?.row ?? {};
      const progressItems =
        filtereditems.map((item) => ({
          ...item,
          isTaskFlagged:
            item.id === row.id ? isTaskFlagged : item.isTaskFlagged,
          isTaskFlaggedInProgress:
            item.id === row.id ? false : item.isTaskFlaggedInProgress,
        })) ?? [];
      setFilteredItems(progressItems);

      const taskItems =
        items.map((item) => ({
          ...item,
          isTaskFlagged:
            item.id === row.id ? isTaskFlagged : item.isTaskFlagged,
        })) ?? [];

      setItems(taskItems);
    },
  });

  const taskCompletionMutation = useMutation({
    mutationFn: async (row: any) => {
      await updateLearnerSyllabusTaskCompletedState(row.id, learner.email, blxpData?.appConfig!);
    },
    onMutate: (row: any) => {
      const isTaskCompleted = !row.isTaskCompleted;
      const newItems =
        filtereditems.map((item) => ({
          ...item,
          isTaskCompleted:
            item.id === row.id ? isTaskCompleted : item.isTaskCompleted,
          isTaskCompletedInProgress:
            item.id === row.id ? true : item.isTaskCompletedInProgress,
        })) ?? [];
      setFilteredItems(newItems);

      return { isTaskCompleted: isTaskCompleted, row: row };
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["UserTasksData"]);
      const isTaskCompleted = context?.isTaskCompleted ?? false;
      const row = context?.row ?? {};
      const progressItems =
        filtereditems.map((item) => ({
          ...item,
          isTaskCompleted:
            item.id === row.id ? isTaskCompleted : item.isTaskCompleted,
          isTaskCompletedInProgress:
            item.id === row.id ? false : item.isTaskCompletedInProgress,
        })) ?? [];
      setFilteredItems(progressItems);

      const taskItems =
        items.map((item) => ({
          ...item,
          isTaskCompleted:
            item.id === row.id ? isTaskCompleted : item.isTaskCompleted,
        })) ?? [];

      setItems(taskItems);
    },
  });

  const onFlaggedClick = async (event: React.MouseEvent, row: Item) => {
    await taskFlagMutation.mutateAsync(row);
  };

  const onCompletedChange = async (
    event: React.MouseEvent<HTMLInputElement>,
    row: Item
  ) => {
    await taskCompletionMutation.mutateAsync(row);
  };

  const onSeeMoreChange = (
    event?: any,
    selectedRowItem?: LearnerSyllabusTask
  ) => {
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item, index) => {
        const { isSeeMoreOpen: prevSeeMoreOpen } = prevItems[index];
        const isSelectedItem = item.id === selectedRowItem?.id;
        return {
          ...item,
          isSeeMoreOpen: isSelectedItem ? !prevSeeMoreOpen : prevSeeMoreOpen,
        };
      });
      return updatedItems;
    });
  };

  const isFutureSession = (item: LearnerSyllabusTask) => {
    const startTime = convertToLocalTimeZone(
      new Date(Date.parse(item.taskStartDateTime))
    ).getTime();
    const currentTime = Date.now();
    return startTime > currentTime;
  };

  const isShowJoinSessionButton = (item: LearnerSyllabusTask) => {
    const endTime = convertToLocalTimeZone(
      new Date(Date.parse(item.taskEndDateTime))
    ).getTime();
    const startTime = convertToLocalTimeZone(
      new Date(Date.parse(item.taskStartDateTime))
    ).getTime();
    const currentTime = Date.now();

    return currentTime >= startTime - 1800000 && currentTime < endTime;
  };

  const setTaskCompletion = (item: LearnerSyllabusTask) => {
    ClarityHelper.setCustomTag(`TaskCompletion`, `${item.syllabusTask.name}`);
    ClarityHelper.setCustomTag(`TaskNumber`, `${item.syllabusTask.taskOrderNumber}`);
    if (!item.isTaskCompleted) {
      onTaskCompleted(item);
    }
  };
  const onTaskCompleted = async (item: LearnerSyllabusTask) => {
    await taskCompletionMutation.mutateAsync(item);
  };

  const toggleIsExpand = (event?: any) => {
    ai.appInsights?.trackEvent({ name: "Send Invites Dialog: Invites sent." });
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const tasksToShow =
    !isShowSelectedFousArea || isExpanded
      ? filtereditems
      : filtereditems.slice(0, 11);

  const sortedTasksToShow = tasksToShow.sort((a, b) =>
    a.syllabusTask.taskOrderNumber.localeCompare(b.syllabusTask.taskOrderNumber)
  );
  const [mediaSASToken, setMediaSASToken] = useState<mediaAccessResult>();
  const { data: mediaToken, isLoading: isMediaSASTokenLoading } = useQuery({
    queryKey: ["MediaSASToken"],
    queryFn: () => getMediaSASToken("videos", "1", BlendedLearningContext.getInstance()?.appConfig!),
    staleTime: 86400000, // 23 hours
  });

  const validateLearnerRegistration = useMutation({
    mutationFn: async () => {
      const response=await validateRegistration(blxpData?.learner.learnerId as string, blxpData?.delivery?.deliveryId as string, blxpData?.learner.orgId as string, blxpData?.appConfig!);
    if(response){
      queryClient.invalidateQueries(["UserTasksData"]);
    }
    }
  });

  return (
    <> 
    <mediaAccessContext.Provider value={mediaToken}>
      <Table aria-label="Table with controlled multiselect" tabIndex={0}>
        <TableHeader>
          <TableRow role="column">
            <TableHeaderCell className={classes.smCol} aria-hidden={false} aria-label="Flag" scope="col">
              <Text>Flag</Text>
            </TableHeaderCell>
            <TableHeaderCell className={classes.mdCol} aria-hidden={false} aria-label="Complete" scope="col">
              <Text>Complete</Text>
            </TableHeaderCell>
            <TableHeaderCell className={classes.mdCol} aria-hidden={false} aria-label="Task" scope="col">
              <Text>Task</Text>
            </TableHeaderCell>
            <TableHeaderCell
              className={classes.xlCol}
              aria-label="Task list"
              tabIndex={-1}
              aria-hidden={false}
              role="cell"
            ></TableHeaderCell>
            <TableHeaderCell className={classes.mdCol} aria-hidden={false} aria-label="Time" scope="col">
              <Text>Time</Text>
            </TableHeaderCell>
            <TableHeaderCell className={classes.lgCol} aria-hidden={false} aria-label="Platform" scope="col">
              <Text>Platform</Text>
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedTasksToShow.map((item: LearnerSyllabusTask, index) => (
            <TableRow
              key={index}
              id={index.toString()}
              className={classes.marginTop}
            >
              <TableCell
                aria-label="Flag this session"
                className={classes.smCol}
              >
                {!item.isTaskFlaggedInProgress ? (
                  <TableCellLayout className={classes.positionTop}>
                    <Tooltip content="Flag this task" relationship="label">
                      <Button
                        appearance="transparent"
                        onClick={(e) => onFlaggedClick(e, item)}
                        aria-label={
                          item.isTaskFlagged
                            ? "Session flagged"
                            : "Session not flagged"
                        }
                        icon={
                          item.isTaskFlagged ? (
                            <Flag24Filled
                              className="flaggedColor"
                              aria-label="Unflag this task"
                            />
                          ) : (
                            <Flag24Regular
                              role="button"
                              aria-label="Flag this task"
                            />
                          )
                        }
                      />
                    </Tooltip>
                  </TableCellLayout>
                ) : (
                  <div className="cellLoader">
                    <Spinner size="tiny" labelPosition="after" />
                  </div>
                )}
              </TableCell>
              <TableCell
                aria-label="Select this session"
                className={classes.mdCol}
              >
                <TableCellLayout className={classes.positionTop}>
                  <Tooltip content="Select this task" relationship="label">
                    {!item.isTaskCompletedInProgress ? (
                      <Checkbox
                        checked={item.isTaskCompleted}
                        shape="circular"
                        onClick={(e) => onCompletedChange(e, item)}
                        aria-label="Checkbox for completed tasks"
                      />
                    ) : (
                      <div className="cellLoader">
                        <Spinner size="tiny" labelPosition="after" />
                      </div>
                    )}
                  </Tooltip>
                </TableCellLayout>
              </TableCell>
              <TableCell className={classes.mdCol} tabIndex={0}>
                <TableCellLayout className={classes.positionTop}>
                  {getFormattedTaskOrderNumber(
                    item.syllabusTask.taskOrderNumber
                  )}
                </TableCellLayout>
              </TableCell>
              <TableCell className={classes.xlCol}>
                <TableCellLayout className={classes.marginTop}>
                  <CustomIcon
                    iconName={item.syllabusTask.syllabusTaskType.name}
                  />
                  <span
                    className={classes.textAligned}
                    aria-label={item.syllabusTask.syllabusTaskType.name}
                  > 
                    <ActionLabel item={item} setTaskCompletion={setTaskCompletion} />
                    
                  </span>
                  {item.isSeeMoreOpen ? (
                    <MoreInfo item={item} onContinue={handleClickContinue} />
                  ) : (
                    ""
                  )}
                </TableCellLayout>
              </TableCell>
              <TableCell className={classes.mdCol} tabIndex={0}>
                <TableCellLayout className={classes.positionTop}>
                  <DurationTimeLabel
                    durationInMinutes={item.syllabusTask.durationInMinutes}
                  />
                </TableCellLayout>
              </TableCell>
              <TableCell className={classes.lgCol} tabIndex={0}>
                <TableCellLayout
                  className={mergeClasses(
                    classes.platformContainer,
                    classes.positionTop
                  )}
                >
                  <div className="platformNameWrapper">
                    {item.syllabusTask.platform.name}
                    {(item.syllabusTask.platform.name === "Microsoft Teams" &&
                      item.syllabusTask.syllabusTaskType.name ===
                        "Chalk Talks") ||
                    (item.syllabusTask.platform.name === "Microsoft Teams" &&
                      item.syllabusTask.syllabusTaskType.name ===
                        "Office Hours") ||
                    (item.syllabusTask.platform.name === "Microsoft Teams" &&
                      item.syllabusTask.syllabusTaskType.name ===
                        "Lab Sessions") ? (
                      <Button
                        appearance="transparent"
                        target="_self"
                        key={index}
                        onClick={(e) => onSeeMoreChange(e, item)}
                        className={classes.buttonStyles}
                        aria-expanded={item.isSeeMoreOpen}
                        aria-label="see more"
                        icon={
                          !item.isSeeMoreOpen ? (
                            <ChevronDown24Filled />
                          ) : (
                            <ChevronUp24Filled />
                          )
                        }
                      ></Button>
                    ) : (
                      ""
                    )}
                  </div>
                  {item.isSeeMoreOpen && (
                    <>
                      {isFutureSession(item) &&
                        !isShowJoinSessionButton(item) && (
                          <SendInviteDialog
                            task={item}
                            emailAddress={blxpData?.learner?.email}
                          />
                        )}
                    </>
                  )}

                  {item.isSeeMoreOpen && isShowJoinSessionButton(item) && (
                    <JoinSessionButton item={item} />
                  )}
                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
          {((tasksData !=null && isShowPdfTask) &&
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <PdfDocument 
               tasks={tasksData} 
               deliveryId={blxpData?.delivery.deliveryId}
               courseName={blxpData?.delivery.course.name} />              
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          )}
        </TableBody>
      </Table>
      {isShowSelectedFousArea && filtereditems.length > 10 && (
        <div className={classes.seeMoreButtonWrapper}>
          <Button
            icon={isExpanded ? <ChevronUp24Filled /> : <ChevronDown24Filled />}
            iconPosition="after"
            onClick={toggleIsExpand}
          >
            {isExpanded ? "Collapse" : "Expand"}
          </Button>
        </div>
      )}
      </mediaAccessContext.Provider>
    </>
  );
};

function clone(learnerSyllabusTasks: any): LearnerSyllabusTask[] {
  return learnerSyllabusTasks
    ? JSON.parse(JSON.stringify(learnerSyllabusTasks))
    : [];
}

export default SyllabusTable;
