import { Body1, Spinner, Text } from "@fluentui/react-components";
import { Widget } from "../lib/Widget";
import "../styles/Common.css";
import "../styles/HomeOverview.scss"
import { yourProgressWidgetStyle } from "../lib/YourProgressWidget.styles";
import { FocusAreaConstants } from "../../common/focusAreaConstants";
import { BlendedLearningContext } from "../../internal/singletonContext";
import { TopicTypes } from "../../common/topicTypes";
import { getTopicsByDeliveryId } from "../../services/deliveryService";
import { teamsGetChannelId } from "botbuilder";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";


interface IFocusAreaOverview {
    channelName: string,
    channelId: string,
    topicName: string,
    topicDescription: string
}



export class FocusAreaOverviewWidget extends Widget<IFocusAreaOverview> {
    protected async getData(): Promise<IFocusAreaOverview> {
        const blxpMainData = await BlendedLearningContext.getInstance().getBlendedLearning();

        let deliveryTopicData = await getTopicsByDeliveryId(blxpMainData.delivery.id,blxpMainData.channelId, blxpMainData.appConfig);
        let topicDetails =  deliveryTopicData && deliveryTopicData.length > 0 ? deliveryTopicData[0] : null;
        if(!topicDetails){
            ai.appInsights?.trackEvent({ name: 'No Matching Topics found for ' + blxpMainData.channelName + blxpMainData.delivery.deliveryId});
        }

        return {
            topicName: topicDetails?.topics?.name,
            channelName: blxpMainData.channelName,
            channelId: blxpMainData.channelId,
            topicDescription: topicDetails?.topics.description
        } as IFocusAreaOverview;
    }


    protected headerContent(): JSX.Element | undefined {
        return (
            <h1 className={yourProgressWidgetStyle.headerText} aria-label="Overview" tabIndex={0}>Overview</h1>

        );
    }

    protected bodyContent(): JSX.Element | undefined {

        return (
            <>
                <Body1>
                    {this.state.topicDescription}
                </Body1>
            </>

        );
    }
    protected loadingContent(): JSX.Element | undefined {
        return (
            <div className="loading-layout">
                <Spinner label="Loading..." labelPosition="below" />
            </div>
        );
    }




}