import * as axios from "axios";

import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";

import { IApplicationConfig } from "../common/settings";
import { HomeOverview } from "../models/homeOverview";
import { PagedResponse } from "../models/pagedResponse";
import { GetDefaultRequestConfig } from "../common/ServiceConfiguration";
import Logger from "../common/logger";
import { authentication } from "@microsoft/teams-js";
import { ILiveSessionStatus } from "../models/liveSessionStatus";
import Helper from "../common/helper";

// const functionName = process.env.REACT_APP_API_BLENDEDLEARNINGSERVICES_URL + "/api/";
export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

function onErrorBlendedLearingService(err: unknown) {


  if (axios.default.isAxiosError(err)) {
    let apiErrorMsg = "";

    if (err?.response?.status === 404) {
      apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
    } else if (err.message === "Network Error") {
      apiErrorMsg =
        "Cannot call Azure Function due to network error, please check your network connection status and ";
      if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
        apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
      } else {
        apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
      }
    } else {
      apiErrorMsg = err.message;
      if (err.response?.data?.error) {
        apiErrorMsg += ": " + err.response.data.error;
      }
    }

    Logger.LogError(apiErrorMsg + "\n" + err);
    return undefined;
  }
  Logger.LogError('', err);
  return undefined;

}

export async function getHomeOverview(appConfig: IApplicationConfig): Promise<HomeOverview[]> {



  let token = "";
  try {
    token = await authentication.getAuthToken();
  } catch (err: unknown) {

    Logger.LogError('', err);
    return [];
  }

  const service = appConfig.services.apiServices;

  // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
  const apiClient = createApiClient(
    service.baseUri,
    new BearerTokenAuthProvider(async () => token)
  );
  const endpoint = Helper.format(`${service.endpoints.HomeOverView}`);

  const requestConfig = await GetDefaultRequestConfig(service, false);

  const response = await Helper.assertGetRequest<PagedResponse<HomeOverview> | undefined>("getHomeOverview()", endpoint, requestConfig, apiClient, onErrorBlendedLearingService,appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);

  return response?.data?.items ?? [];

}

export async function updateLearnerWelcomeTourState(deliveryId: string, learnerId: string, appConfig: IApplicationConfig) {

  let token = "";
  try {
    token = await authentication.getAuthToken();
  } catch (err: unknown) {

    Logger.LogError('', err);
    return undefined;
  }

  const service = appConfig.services.apiServices;
  const endpoint = Helper.format(service.endpoints.PutLearnerDeliveryWelcomeTourStatus as string, deliveryId, learnerId);

  // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
  const apiClient = createApiClient(
    service.baseUri,
    new BearerTokenAuthProvider(async () => token)
  );

  const requestConfig = await GetDefaultRequestConfig(service);

  const response = await Helper.assertPutRequest<any | undefined>("updateLearnerWelcomeTourState()", endpoint, undefined, requestConfig, apiClient, onErrorBlendedLearingService,appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
  return response?.data;


}

export async function getLearnerWelcomeTourState(deliveryId: string, learnerId: string, appConfig: IApplicationConfig) {



  let token = "";
  try {
    token = await authentication.getAuthToken();
  } catch (err: unknown) {

    Logger.LogError('', err);
    return undefined;
  }

  const service = appConfig.services.apiServices;
  const endpoint = Helper.format(service.endpoints.GetLearnerDeliveryWelcomeTourStatus as string, deliveryId, learnerId);

  // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
  const apiClient = createApiClient(
    service.baseUri,
    new BearerTokenAuthProvider(async () => token)
  );

  const requestConfig = await GetDefaultRequestConfig(service);
  const response = await Helper.assertGetRequest<any>("getLearnerWelcomeTourState()", endpoint, requestConfig, apiClient, onErrorBlendedLearingService,appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
  return response.data;


}

export async function getLearnerSyllabusTasks(learnerId: string, deliveryId: string, appConfig: IApplicationConfig) {

  let token = "";
  try {
    token = await authentication.getAuthToken();
  } catch (err: unknown) {

    Logger.LogError('', err);
    return undefined;
  }
  const service = appConfig.services.apiServices;
  const endpoint = Helper.format(service.endpoints.LearnerSyllabusTasks as string, learnerId, deliveryId);
  // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
  const apiClient = createApiClient(
    service.baseUri,
    new BearerTokenAuthProvider(async () => token)
  );

  const requestConfig = await GetDefaultRequestConfig(service);
  const response = await Helper.assertGetRequest<any>("getLearnerSyllabusTasks()", endpoint, requestConfig, apiClient, onErrorBlendedLearingService,appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
  return response.data.items;

}

export async function getLearnerSyllabusTaskLabUrl(learnerSyllabusTaskId: string, locale: string, appConfig: IApplicationConfig) {

  let token = "";
  try {
    token = await authentication.getAuthToken();
  } catch (err: unknown) {

    Logger.LogError('', err);
    return undefined;
  }
  const service = appConfig.services.apiServices;
  const endpoint = Helper.format(service.endpoints.getLearnerSyllabusTaskLabUrl as string, learnerSyllabusTaskId, locale);
  // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
  const apiClient = createApiClient(
    service.baseUri,
    new BearerTokenAuthProvider(async () => token)
  );

  const requestConfig = await GetDefaultRequestConfig(service, false);
  const response = await Helper.assertGetRequest<any | undefined>("getLearnerSyllabusTaskLabUrl()", endpoint, requestConfig, apiClient, onErrorBlendedLearingService,appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
  return response?.data;

}

export async function getTrainerClassViewUrl(deliveryId: string, appConfig: IApplicationConfig) {


  let token = "";
  try {
    token = await authentication.getAuthToken();
  } catch (err: unknown) {

    Logger.LogError('', err);
    return undefined;
  }
  const service = appConfig.services.apiServices;
  const endpoint = Helper.format(service.endpoints.getTrainerClassViewUrl as string, deliveryId);
  // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
  const apiClient = createApiClient(
    service.baseUri,
    new BearerTokenAuthProvider(async () => token)
  );

  const requestConfig = await GetDefaultRequestConfig(service, false);
  const response = await Helper.assertGetRequest<any>("getTrainerClassViewUrl()", endpoint, requestConfig, apiClient, onErrorBlendedLearingService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
  return response.data;

}

export async function getLiveSessionStatus(learnerId: string, deliveryId: string, appConfig: IApplicationConfig): Promise<ILiveSessionStatus> {


  let token = "";
  try {
    token = await authentication.getAuthToken();
  } catch (err: unknown) {

    Logger.LogError('', err);
    return {} as ILiveSessionStatus;
  }
  const service = appConfig.services.apiServices;
  const endpoint = Helper.format(service.endpoints.getLiveSession as string, learnerId, deliveryId);

  // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
  const apiClient = createApiClient(
    service.baseUri,
    new BearerTokenAuthProvider(async () => token)
  );
  const requestConfig = await GetDefaultRequestConfig(service);
  const response = await Helper.assertGetRequest<ILiveSessionStatus | undefined>("getLiveSessionStatus()", endpoint, requestConfig, apiClient, onErrorBlendedLearingService,appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
  return response?.data ?? {} as ILiveSessionStatus;

}


export async function saveLiveSessionStatus(learnerId: string, deliveryId: string, liveSessionStatus: ILiveSessionStatus, appConfig: IApplicationConfig): Promise<ILiveSessionStatus> {

  let token = "";
  try {
    token = await authentication.getAuthToken();
  } catch (err: unknown) {

    Logger.LogError('', err);
    return {} as ILiveSessionStatus;
  }
  const service = appConfig.services.apiServices;
  const endpoint = Helper.format(service.endpoints.saveLiveSession as string, learnerId, deliveryId);

  // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
  const apiClient = createApiClient(
    service.baseUri,
    new BearerTokenAuthProvider(async () => token)
  );
  const requestConfig = await GetDefaultRequestConfig(service);
  const response = await Helper.assertPutRequest<ILiveSessionStatus | undefined>("saveLiveSessionStatus()", endpoint, liveSessionStatus, requestConfig, apiClient, onErrorBlendedLearingService,appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
  return response?.data ?? {} as ILiveSessionStatus;

}