import React from "react";
import LabLink from "./LabLink";
import { LearnerSyllabusTask } from "../../models";
import LaunchLink from "./LaunchLink";
import { Text, makeStyles } from "@fluentui/react-components";
import VideoDialog from "./VideoDialog";

interface Props {
  item: LearnerSyllabusTask;
  setTaskCompletion:(task: LearnerSyllabusTask)=>void;
}

const useStyles = makeStyles({
  alignLink: {   
    whiteSpace: "normal",
  }
});
const ActionLabel: React.FC<Props> = ({ item, setTaskCompletion }) => {
   let link;
  const [isOpen, setIsOpen] = React.useState(false);
  // const [showLearnDialog, setShowLearnDialog] = React.useState(false);
  const onDismiss = () => {
    setIsOpen(false);
  }; 
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setIsOpen(true);
    }
  };
  const classes = useStyles();
  switch (item.syllabusTask.syllabusTaskType.name) {
    case "Labs":
      link = <LabLink learnerSyllabusTaskId={item.id} linkText={item.syllabusTask.name} locale="en-us" />;
      break;
    case "Modules":
      link = (item.syllabusTask.taskOrderNumber.startsWith('0') || item.syllabusTask.taskOrderNumber.startsWith('5')) ?  
      <LaunchLink task={item} deliveryLevelUrl={true} includeMSLearnSSOParams={false} setTaskCompletion={setTaskCompletion} /> 
      : <LaunchLink task={item} deliveryLevelUrl={false} includeMSLearnSSOParams={true} setTaskCompletion={setTaskCompletion} /> 
      break;
    case "Videos":
      if (item.syllabusTask.description === "Course Overview"){
       // const courseOverviewLink = 'https://aka.ms/ESIBlended_CourseOverviewVideo';
        link = <LaunchLink task={item} deliveryLevelUrl={true} includeMSLearnSSOParams={false} setTaskCompletion={setTaskCompletion} />
      }
      else{
      link = <VideoDialog isOpen={isOpen} onDismiss={onDismiss} task={item} onPlayVideo={setTaskCompletion} showPlayButton={false} />;
      }
      break;
    case "Survey":
      link = <LaunchLink task={item} deliveryLevelUrl={true} includeMSLearnSSOParams={false} setTaskCompletion={setTaskCompletion} /> 
      break;
    default:
      link = <Text>{item.syllabusTask.name}</Text>;
      break;
  }

  return (
    <span 
    className={classes.alignLink}
    >{link}</span>
  );
};

export default ActionLabel;