import { IFeatureFlag } from "../models/featureFlag";
import { DISABLE_TPID_FLAG } from "./constants";
import { IServiceConfiguration } from "./ServiceConfiguration";

export interface IApplicationConfig {
    isLoaded: boolean;
    services: {
      [key: string]: IServiceConfiguration;
    };
    appInsightsConnectionString: string;
    defaultAuthScopes: string[];
    clarityProjectId: string;
    featureFlagList: IFeatureFlag[]
    featureFlags?: {
      disableTpid?: IFeatureFlag;
    };
    enrollmentVersion: number;
  }

   export const defaultApplicaitonConfig: IApplicationConfig = {
    isLoaded: false,
    enrollmentVersion: 2,
    featureFlagList: [],
    services: {
      apiServices: {
        baseUri: process.env.REACT_APP_API_BLENDEDLEARNINGSERVICES_URL as string,
        apiKey: process.env.REACT_APP_API_BLENDEDLEARNINGSERVICES_Apim_Subscription_Key as string,
        endpoints: {
          readFeatureFlag: '/Enrollment/v2/api/Profile/ReadFeatureFlag', 
          HomeOverView: '/blxp/v1/homeOverviews',
          Sessions:  "/Enrollment/v{0}/api/EventManagement/eventSessions?deliveryId={1}&learnerId={2}&tpId={3}",
          SessionsV2:  "/Enrollment/v{0}/api/EventManagement/eventSessions?deliveryId={1}&learnerId={2}&skillingId={3}",
          patchSessions: `/Enrollment/v{0}/api/EventManagement/eventSessions`,
          teamsSessions: "/blxp/v1/teamsSessions/{0}/delivery",
          Delivery: "/blxp/v1/deliveries/{0}",
          CourseTopic: "/blxp/v1/courses/{0}/topics",
          PutLearnerDeliveryWelcomeTourStatus: "/blxp/v1/homeOverviews/LearnerDeliveryWelcomeTourStatus/{0}/{1}",
          GetLearnerDeliveryWelcomeTourStatus: "/blxp/v1/homeOverviews/LearnerDeliveryWelcomeTourStatus/{0}/{1}",      
          LearnerSyllabusTasks: "/blxp/v1/syllabuses/learnersyllabustasks/{0}/{1}",
          UpdateLearnerSyllabusTaskCompletedState: "/blxp/v1/syllabuses/updatelearnersyllabustaskcompletedstate/{0}/{1}",
          UpdateLearnerSyllabusTaskFlaggedState: "/blxp/v1/syllabuses/updatelearnersyllabustaskflaggedstate/{0}/{1}",
          DeliveryTopic: "/blxp/v1/deliveries/",
          getLearnerSyllabusTaskLabUrl: "/blxp/v1/labs/learnerLabUrl?learnerSyllabusTaskId={0}&locale={1}",
          getTrainerClassViewUrl:  "/blxp/v1/labs/TrainerLabUrl/{0}",
          getLearnerTeamState:  "/blxp/v1/teamsSessions/learnerteamstate/{0}/{1}",
          saveLearnerTeamState: "/blxp/v1/teamsSessions/learnerteamstate/{0}/{1}",        
          getLearnerProfile: "/profile/v1/Profile/GetLearnerProfile?preferredEmailAddress={0}", 
          getLearnerProfileUsingWorkEmail: "/profile/v1/Profile/GetLearnerProfile?workEmail={0}",
          getMediaAccess: "/blxp/v1/media/authorize?mediaName={0}&mediaResourceType={1}",
          getLiveSession: "/blxp/v1/syllabuses/getliveSession/{0}/{1}",
          saveLiveSession: "/blxp/v1/syllabuses/saveliveSession/{0}/{1}",
          postTrainerData: "/blxp/v1/deliveries/trainer?learnerId={0}&deliveryId={1}&tpId={2}", 
          validateRegistration: "/blxp/v1/learners/validateRegistration?learnerId={0}&deliveryId={1}&tpId={2}", 
          postTrainerDataV2: "/blxp/v1/deliveries/trainerV2?learnerId={0}&deliveryId={1}&skillingId={2}", 
          validateRegistrationV2: "/blxp/v1/learners/validateRegistrationV2?learnerId={0}&deliveryId={1}&skillingId={2}" 
        }
      }
    },
    appInsightsConnectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING as string,
    clarityProjectId: process.env.REACT_APP_CLARITY_PROJECT_ID as string,
    defaultAuthScopes: ['api://' + process.env.REACT_APP_AUTHENTICATION_CLIENT_ID + '/user_impersonation']
  };

  export interface IFeatureFlagState {
    isLoaded: boolean;
    pending: boolean;
    featureFlags: IFeatureFlag[];
    customErrorMessage: string | null;
  }
  export const initialFeatureFlagState = {
    isLoaded: false,
    pending: false,
    featureFlags: [],
    customErrorMessage: null
  };


  export function initApplicationConfig(featureFlags: IFeatureFlag[] | undefined): IApplicationConfig {

    const disableTpId = featureFlags?.find((flag: IFeatureFlag) => flag.flagName === DISABLE_TPID_FLAG);
  

      return {
        ...defaultApplicaitonConfig,
        featureFlagList: featureFlags ?? [],
        featureFlags: {
          disableTpid: disableTpId
        },
        isLoaded: true,
        //v5 introduction of skillingid
        enrollmentVersion:  disableTpId?.isEnabledFlag == true ? 5 : 2
      }
  }
