import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BlendedLearningContext } from "../../internal/singletonContext";
import { Button, Title2 } from "@fluentui/react-components";
import { getLearnerSyllabusTasks, getTrainerClassViewUrl } from "../../services/blendedLearningService";
import { postTrainerData } from "../../services/learnerService";
import { LearnerSyllabusTask } from "../../models";



export const TrainerControl: React.FunctionComponent = () => {
  const [items, setItems] = useState<LearnerSyllabusTask[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
 
  const { data: blxpData, isLoading: blxpDataLoading } = useQuery({
    queryKey: ["BlxpData"],
    queryFn: () => BlendedLearningContext.getInstance().getBlendedLearning(),
  });

  const fetchUrl = useMutation({
    mutationFn: () => {
      return getTrainerClassViewUrl(blxpData?.delivery?.id as string, blxpData?.appConfig!);
    },
  });
  const createTrainerTask = useMutation({
    mutationFn: async () => {
      await postTrainerData(blxpData?.learner.learnerId as string, blxpData?.delivery?.deliveryId as string, blxpData?.learner.orgId as string, blxpData?.appConfig!);
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: async () => {
      const response = await getLearnerTasks.mutateAsync();
      setItems(response);
      setIsLoading(false);

    }
  });
  const handleClick = async () => {
    const response = await fetchUrl.mutateAsync();

    window.open(response, "_blank");
  };
  const handleCreateTrainerTask = async () => {
    await createTrainerTask.mutateAsync();

  };

  const getLearnerTasks = useMutation({
    mutationFn: async () => {
      return await getLearnerSyllabusTasks(blxpData?.learner?.id as string, blxpData?.delivery?.id as string, blxpData?.appConfig!);
    }
  });
  const { data: tasksData, isLoading: isTasksDataLoading } = useQuery({
    queryKey: ["UserTasksData"],
    queryFn: () => getLearnerSyllabusTasks(blxpData?.learner?.id as string, blxpData?.delivery?.id as string, blxpData?.appConfig!),
    enabled: blxpData != null && blxpData != undefined
  });
  useEffect(() => {
    if (blxpDataLoading) {
    }
  }, [blxpData, blxpDataLoading,]);
  useEffect(() => {
    if (tasksData != null && blxpData != null && items) {
      setItems(tasksData);
    }
  }, [tasksData, isTasksDataLoading]);
  return (
    <>
      Logged in as {blxpData?.learner?.email}
      <br />
      <Title2 as="h1"> Welcome to the Trainer Control Dashboard!</Title2><br />
      <Button onClick={handleClick}>Over The Shoulder View</Button>
      {((items == null || items.length == 0) && blxpData?.learner?.id != undefined && blxpData?.delivery?.id != undefined) &&
        <Button onClick={handleCreateTrainerTask}>{isLoading ? 'Loading..' : 'Generate Task'}</Button>
      }   
      <div>Total Tasks : {items != null && items.length>0 ? items.length : 0}</div>

    </>
  );
}

export default TrainerControl;