import { CSSProperties } from "react";
import { Dashboard } from "../lib/Dashboard";
import { HomeOverviewWidget } from "../widgets/HomeOverviewWidget";
import LiveSessionWidget from "../widgets/LiveSessionWidget";
import { SyllabusWidget } from "../widgets/SyllabusWidget";
import { YourProgressWidget } from "../widgets/YourProgressWidget";
import { BackToTop } from "../components/BackToTop";

import "../styles/MyDashboard.css";

export default class Home extends Dashboard {
  protected dashboardLayout(): JSX.Element | undefined {
    return (
      <>
        <HomeOverviewWidget />
        <LiveSessionWidget />
        <div className={`${!this.state.isMobile ? "oneRow" : ""}`}>
          <YourProgressWidget
            currentFocusArea={""}
            isShowHeader={true}
            isShowRightColumn={false}
          />
          <div className="separator"></div>
          <SyllabusWidget />
        </div>
        <BackToTop />
      </>
    );
  }

  protected columnWidths(): string | undefined {
    return "4fr 7fr";
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  protected customiseDashboardStyle(): CSSProperties | undefined {
    return this.state.showLogin === false
      ? {
          marginTop: "5%",
        }
      : {
          padding: 0,
          marginTop: 0,
        };
  }
}
