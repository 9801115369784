import { AxiosResponse } from "axios";
import { LearnerSyllabusTask } from "../models";
import { AxiosInstance } from "@microsoft/teamsfx";


export default class Helper{


  public static filterLiveSessions(liveSessionsData: LearnerSyllabusTask[]) {

    return liveSessionsData.filter((session) => (session.syllabusTask.platform.name === "Microsoft Teams" &&
      session.syllabusTask.syllabusTaskType.name ===
          "Chalk Talks") ||
      (session.syllabusTask.platform.name === "Microsoft Teams" &&
      session.syllabusTask.syllabusTaskType.name ===
          "Office Hours") ||
      (session.syllabusTask.platform.name === "Microsoft Teams" &&
      session.syllabusTask.syllabusTaskType.name ===
          "Lab Sessions"));
  }

    public static getCurrentWeekForBL(eventStartDate: Date, eventEndDate: Date, currentDateTimeNow: Date) {
        let currentWeekNumber = 0;
    
        if (currentDateTimeNow < eventStartDate) {
          currentWeekNumber = 0;
        } else if (currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 6)) {
          currentWeekNumber = 1;
        } else if (
          currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 6) &&
          currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 13)
        ) {
          currentWeekNumber = 2;
        } else if (
          currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 13) &&
          currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 20)
        ) {
          currentWeekNumber = 3;
        } else if (currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 20) && currentDateTimeNow < eventEndDate) {
          currentWeekNumber = 4;
        } else if (currentDateTimeNow >= eventEndDate) {
          currentWeekNumber = 5;
        }
        return currentWeekNumber;
      }

      public static addDaysToDate(date: Date, days: number) {
        const updatedDate = new Date(date);
        updatedDate.setDate(date.getDate() + days);
        return updatedDate;
      }
      //Hard-coded for now
      public static getLocale(){
        const locale = "en-US";
        return locale; 
      }

      //Helper for string interpolation by index
      public static format(val : string = "", ...args : any[]) {
       
        return val.replace(/{(\d+)}/g, function(match, number) { 
          return typeof args[number] != 'undefined'
            ? args[number]
            : match
          ;
        });
      };

  public static async assertGetRequest<T>(funcName: string, endpoint: string, apiConfig: { params: {}, headers: {} }, apiClient: AxiosInstance, onError: any, isDebug: boolean = false): Promise<AxiosResponse<T>> {

    if (isDebug)
     console.log(`${funcName} : GET request: ${endpoint}`);

    try {
      const response = await apiClient.get<T>(endpoint, apiConfig);

      if (isDebug)
        console.log(`${funcName} : GET request successful response: ${JSON.stringify(response.data)}`);

      return response as AxiosResponse<T>;
    } catch (err: unknown) {

      if (typeof onError === 'function')
        return onError(err);
      else
        throw err;
    }
  }
  public static async assertPutRequest<T>(funcName: string, endpoint: string, data: any, apiConfig: { params: {}, headers: {} }, apiClient: AxiosInstance, onError: any, isDebug: boolean = false): Promise<AxiosResponse<T>> {

    if (isDebug)
      console.log(`${funcName} : PUT request: ${endpoint}`);

    try {
      const response = await apiClient.put<T>(endpoint, data, apiConfig);

      if (isDebug)
       console.log(`${funcName} : PUT request successful response: ${JSON.stringify(response.data)}`);

      return response as AxiosResponse<T>;
    } catch (err: unknown) {

      if (typeof onError === 'function')
        return onError(err);
      else
        throw err;
    }
  }

  public static async assertPatchRequest<T>(funcName: string, endpoint: string, data: any, apiConfig: { params?: {}, headers: {} }, apiClient: AxiosInstance, onError: any, isDebug: boolean = false): Promise<AxiosResponse<T>> {

    if (isDebug)
      console.log(`${funcName} : PATCH request: ${endpoint}`);

    try {
      const response = await apiClient.patch<T>(endpoint, data, apiConfig);

      if (isDebug)
       console.log(`${funcName} : PATCH request successful response: ${JSON.stringify(response.data)}`);

      return response as AxiosResponse<T>;
    } catch (err: unknown) {

      if (typeof onError === 'function')
        return onError(err);
      else
        throw err;
    }
  }

}

