import {
  makeStyles,
  Button,
  Spinner,
  Text,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import { LearnerSyllabusTask } from "../../models";
import {
  CheckmarkRegular,
  Flag24Filled,
  Flag24Regular,
} from "@fluentui/react-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import clsx from "clsx";

import {
  updateLearnerSyllabusTaskCompletedState,
  updateLearnerSyllabusTaskFlaggedState,
} from "../../services/syllabusService";
import CustomIcon from "./CustomIcon";
import "../styles/TaskPopover.scss";
import ActionLabel from "./ActionLabel";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { ClarityHelper } from "../../internal/ClarityHelper";
import { getFormattedTaskOrderNumber } from "../../common/formatTask";
import { BlendedLearningContext } from "../../internal/singletonContext";

interface IComponentProps {
  task: LearnerSyllabusTask;
  learnerEmail: string;
}

const useStyles = makeStyles({
  flaggedButton: {
    ...shorthands.borderColor("#E93A39"),

    color: "#E93A39",
    ":hover": {
      ...shorthands.borderColor("#E93A39"),
      color: "#E93A39",
    },
  },
  unflaggedButton: {
    ":hover": {
      ...shorthands.borderColor("#000"),
      color: "#000",
    },
  },
  markedCompleted: {
      backgroundColor: tokens.colorBrandForegroundOnLightHover,
      color: "#FFFFFF",
      fontWeight: "600",
      fontSize: "12px",
  },
  completed: {
    paddingLeft: "0.1em",
  },
  tasktypeContainer: {
    width: "100%",
    float: "left",
    marginTop: "0.2em",
    display: "flex"
  },
  rightBorder: {
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    borderRightColor: "#686868"
  },
  withPadding: {
    paddingTop: "0em",
    paddingRight: "0.5em",
    paddingBottom: "0em",
    paddingLeft: "0.5em",
  },  
  tasktype: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: tokens.colorNeutralStrokeAccessiblePressed
},
  tasktypeName: { 
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px", 
    paddingTop: "0em",
    paddingRight: "0.5em",
    paddingBottom: "0em",
    paddingLeft: "0.2em",
    color: tokens.colorNeutralStrokeAccessiblePressed
  }

});
export const TaskPopover: React.FunctionComponent<IComponentProps> = (
  props: IComponentProps
) => {
  const { task, learnerEmail } = props;
  const [isTaskFlaggedInProgress, setTaskFlaggedInProgress] =
    React.useState<boolean>(false);
  const [isTaskCompletedInProgress, setTaskCompletedInProgress] =
    React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const styles = useStyles();
  const taskFlagMutation = useMutation({
    mutationFn: async () => {
      await updateLearnerSyllabusTaskFlaggedState(task.id, learnerEmail, BlendedLearningContext.getInstance().appConfig!);
    },
    onMutate: () => {
      setTaskFlaggedInProgress(true);
      task.isTaskFlagged = !task.isTaskFlagged;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["UserTasksData"]);
    },
    onSettled: () => {
      setTaskFlaggedInProgress(false);
    },
  });
  const taskCompletionMutation = useMutation({
    mutationFn: async () => {
      await updateLearnerSyllabusTaskCompletedState(task.id, learnerEmail, BlendedLearningContext.getInstance().appConfig!);
    },
    onMutate: () => {
      setTaskCompletedInProgress(true);
      task.isTaskCompleted = !task.isTaskCompleted;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["UserTasksData"]);
    },
    onSettled: () => {
      setTaskCompletedInProgress(false);
    },
  });

  const onFlaggedClick = async () => {
    ai.appInsights?.trackEvent({
      name: "Task Popover: Flag is marked (mouse click).",
    });
    ClarityHelper.setCustomTag("FlagTask", `${task.syllabusTask}`);
    await taskFlagMutation.mutateAsync();
  };
  const onCompletedClick = async () => {
    ai.appInsights?.trackEvent({
      name: "Task Popover: Task is competed (mouse click).",
    });
    ClarityHelper.setCustomTag("CompleteTask", `${task.syllabusTask}`);
    await taskCompletionMutation.mutateAsync();
  };
  const handlePlayVideo = () => {
    if (!task.isTaskCompleted) {
      onCompletedClick();
    }
  };

  return (
    <div className="taskPopoverWrapper">
      <div className="leftCol">
        {getFormattedTaskOrderNumber(task.syllabusTask.taskOrderNumber)}
      </div>
      <div className="rightCol">
        {!isTaskFlaggedInProgress ? (
          <>
            {task.isTaskFlagged ? (
              <Button
                tabIndex={0}
                appearance="transparent"
                aria-label="Flag"
                icon={<Flag24Filled className={styles.flaggedButton} />}
                onClick={onFlaggedClick}
              />
            ) : (
              <Button
                tabIndex={0}
                appearance="transparent"
                aria-label="Unflag"
                icon={<Flag24Regular className={styles.unflaggedButton} />}
                onClick={onFlaggedClick}
              />
            )}
          </>
        ) : (
          <div className="cellLoader">
            <Spinner size="tiny" labelPosition="after" />
          </div>
        )}
      </div>
      <div className="tasknameWraper">
        <span>
          <CustomIcon iconName={task.syllabusTask.syllabusTaskType.name} />
        </span>
        <Text className="taskname">
          <ActionLabel item={task} setTaskCompletion={handlePlayVideo} />
        </Text>
      </div>
      <div className={styles.tasktypeContainer}>
        <div className={clsx(styles, styles.rightBorder, styles.tasktypeName)}>
          {task.syllabusTask.syllabusTaskType.name}
        </div>
        <div className={clsx(styles, styles.rightBorder, styles.withPadding, styles.tasktype)}>
          {task.syllabusTask.durationInMinutes} minutes
        </div>
        <div className={clsx(styles, styles.withPadding, styles.tasktype)}>
          {task.syllabusTask.platform.name}
        </div>
      </div>
      <div className="action">
        {!isTaskCompletedInProgress ? (
          <div className="actionwrapper">
            {task.isTaskCompleted ? (
              <Button
                tabIndex={0}
                className={styles.completed}
                icon={<CheckmarkRegular />}
                onClick={onCompletedClick}
              >
                Completed
              </Button>
            ) : (
              <Button
                tabIndex={0}
                className={styles.markedCompleted}
                onClick={onCompletedClick}
                shape="rounded"
                appearance="subtle"
              >
                Mark as completed
              </Button>
            )}
          </div>
        ) : (
          <Button className={styles.completed} >
            <Spinner size="tiny" labelPosition="after" />
          </Button>
        )}
      </div>
    </div>
  );
};
export default TaskPopover;
