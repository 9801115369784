import axios, { AxiosError } from "axios";
import Logger from "../common/logger";
import {GetDefaultRequestConfig, IServiceConfiguration } from "../common/ServiceConfiguration";
import { IFeatureFlag } from "../models/featureFlag";
import { FEATURE_FLAG_ENDPOINT, FEATURE_FLAGS_QUERY_KEY } from "../common/constants";


export async function getFeatureFlags(): Promise<IFeatureFlag[] | AxiosError> {

    try {

     if(sessionStorage.getItem(FEATURE_FLAGS_QUERY_KEY)){
        return JSON.parse(sessionStorage.getItem(FEATURE_FLAGS_QUERY_KEY)?? "") as unknown as IFeatureFlag[];
    }
     const service : IServiceConfiguration = {
            baseUri: process.env.REACT_APP_API_BLENDEDLEARNINGSERVICES_URL as string,
            apiKey: process.env.REACT_APP_API_BLENDEDLEARNINGSERVICES_Apim_Subscription_Key as string,
            endpoints: {
                readFeatureFlag:  FEATURE_FLAG_ENDPOINT,
            }
        };

        const requestConfig = await GetDefaultRequestConfig(service);
        
        const response = await axios.get<IFeatureFlag[]>(service.baseUri + service.endpoints.readFeatureFlag as string, requestConfig);
        sessionStorage.setItem(FEATURE_FLAGS_QUERY_KEY, JSON.stringify(response?.data));

        return response?.data || [];

    } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy to the cloud") first before running this App`;
            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(funcErrorMsg + "\n" + err);
            return  err as AxiosError;
        }
        Logger.LogError('', err);
        return [];
    }
}