import "./App.css";

import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import {
  FluentProvider,
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsLightTheme,
} from "@fluentui/react-components";
import { useTeamsFx } from "@microsoft/teamsfx-react";
import { app } from "@microsoft/teams-js"
import { TeamsFxContext } from "./internal/context";
import Privacy from "./views/Privacy";
import TabConfig from "./views/TabConfig";
import TermsOfUse from "./views/TermsOfUse";
import HomeDashboard from "./views/dashboards/HomeDashboard";
import FocusAreaDashboard from "./views/dashboards/FocusAreaDashboard";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { ai } from "./internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { defaultApplicaitonConfig, IFeatureFlagState } from "./common/settings"
import CustomErrorMessage from "./views/widgets/CustomErrorMessage";
import { TrainerControl } from "./views/components/TrainerControl";
import { useEffect, useState } from "react";
import { getFeatureFlags } from "./services/featureFlagService";
import { FEATURE_FLAGS_QUERY_KEY } from "./common/constants";
import { IFeatureFlag } from "./models/featureFlag";
import { BlendedLearningContext } from "./internal/singletonContext";

export default function App() {
  const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      retry: false
    }
  }
});


  const { themeString } = useTeamsFx();
  const [featureFlag, setIsFetureFlag] =  useState<IFeatureFlagState>({featureFlags: [], isLoaded: false, pending: false, customErrorMessage: null});
  app.initialize();
  ai.initialize(defaultApplicaitonConfig.appInsightsConnectionString);

  const errorMessage = (error: ErrorEvent) => {
    ai.appInsights?.trackException(error, {name: 'The blended learning app has crashed: ' + error.message});
    console.log('App error: ', error);
    return(
      <CustomErrorMessage />
    );
  }



  //#region Initialization of App Settings -------------------------------

  useEffect(() => {
    queryClient.fetchQuery({
      queryKey: [FEATURE_FLAGS_QUERY_KEY],
      queryFn: getFeatureFlags,
      staleTime: 1000 * 60 * 60
    }).then((data) => {
      setIsFetureFlag({ featureFlags: data as IFeatureFlag[], isLoaded: true, pending: false, customErrorMessage: null });
      BlendedLearningContext.getInstance(data as IFeatureFlag[]);
    }).catch((error) => {
      setIsFetureFlag({ featureFlags: [], isLoaded: false, pending: false, customErrorMessage: error.message });
    }
    );
  }, []);

//#endregion -------------------------------------------------------------
  return (
    <TeamsFxContext.Provider value={{ themeString }} >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AppInsightsErrorBoundary onError={(error) => errorMessage(error)} appInsights={ai.reactPlugin}>
          <AppInsightsContext.Provider value={ai.reactPlugin}>
          <FluentProvider
            theme={
              // teamsLightTheme
              themeString === "dark"
                ? teamsDarkTheme
                : themeString === "contrast"
                ? teamsHighContrastTheme
                : teamsLightTheme
            }
            className="app"
          >
            {featureFlag.isLoaded && Array.isArray(featureFlag.featureFlags) &&
            <Router>
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/trainer" element={<TrainerControl />} />                
                <Route path="/tab" element={<HomeDashboard />} />
                <Route path="/focusarea" element={<FocusAreaDashboard />} />
                <Route path="/config" element={<TabConfig />} />
                <Route path="*" element={<Navigate to={"/tab"} />}></Route>
              </Routes>
            </Router>
            }
          </FluentProvider>
          </AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
      </QueryClientProvider>
    </TeamsFxContext.Provider>
  );
}
