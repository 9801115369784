import "../styles/Common.css";
import "../styles/Task.css";

import React from "react";

import {
  Spinner,
  Text,
} from "@fluentui/react-components";

import { Widget } from "../lib/Widget";
import { liveSessionWidgetStyle } from "../lib/LiveSessionWidget.styles";
import { LearnerSyllabusTask, LiveSession } from "../../models";
import { LiveSessionsTable } from "../components/LiveSessionsTable";
import SessionDialog from "../components/SessionDialog";
import IconTips from "../components/IconTips";
import { BlendedLearningContext } from "../../internal/singletonContext";
import { getLearnerSyllabusTasks, getLiveSessionStatus } from "../../services/blendedLearningService";
import { getLiveSessions } from "../../services/deliveryService";
import Helper from "../../common/helper";
import { ILiveSessionStatus } from "../../models/liveSessionStatus";


interface ILiveSession {
  liveSessions: LearnerSyllabusTask[];
  checkedSessions: LearnerSyllabusTask[];
  openDialog: boolean;
  isClosed: boolean;
  sentSessionRegistrationIds:string[];
}


class LiveSessionWidget extends Widget<ILiveSession> {

  protected async getData(): Promise<ILiveSession> {
    
    let blxpMainData = await BlendedLearningContext.getInstance().getBlendedLearning();
    let enrollmentLiveSessions = await getLiveSessions(blxpMainData.delivery.deliveryId, blxpMainData.learner.id,blxpMainData.learner.orgId, blxpMainData.appConfig!);
    let liveSessionsData = await getLearnerSyllabusTasks(blxpMainData.learner.id, blxpMainData.delivery.id, blxpMainData.appConfig!);
    let liveSessionStatus = await getLiveSessionStatus(blxpMainData.learner.id, blxpMainData.delivery.id, blxpMainData.appConfig!);
    liveSessionsData = Helper.filterLiveSessions(liveSessionsData);

    liveSessionsData = this.mergeInviteStatus(liveSessionsData, enrollmentLiveSessions,liveSessionStatus);

    return {
      liveSessions: liveSessionsData,
      checkedSessions:[],
      openDialog: false,
      isClosed: false,
      sentSessionRegistrationIds:[]
    };
  }

  private onSendInvite = (liveSessionStatus: ILiveSessionStatus) => {  
    this.setState({sentSessionRegistrationIds:liveSessionStatus.sessionRegistrationIds});
   }

  protected headerContent(): JSX.Element | undefined {
    return (
      <>
      <div className={liveSessionWidgetStyle.header}>
        <Text key="text-task-title" className={liveSessionWidgetStyle.headerText} as="h2" aria-label="Upcoming live sessions"  aria-labelledby="text">
          Upcoming live sessions
        </Text>
      </div>
      <div className={liveSessionWidgetStyle.headerIcon}><span ><IconTips  iconTitle="Upcoming live sessions" icon='question' text='Classes can have multiple sessions. Choose the calendar invitations you want to receive.' ariaTextId="liveSessionText"/></span></div>
      </>
    );
  }

  protected bodyContent(): JSX.Element | undefined {

    const onCheckBoxChange = (checkedSessions:  LearnerSyllabusTask[]) => {     
      this.setState({checkedSessions:checkedSessions});
     }

    return (
      this.state.liveSessions == undefined || this.state.liveSessions.length == 0 ? <div className={liveSessionWidgetStyle.bodyNoMoreSessions}><Text >No more live sessions for this course.</Text></div> :
        this.state.liveSessions &&
        <LiveSessionsTable  sessions={this.state.liveSessions?.filter(s => Date.parse(s.taskEndDateTime) > Date.now())?.slice(0, 3)} dashboardWidget={true} selectAllSessionCheckboxLabel={""} checkBoxlLabelText={""}  onCheckBoxChange={onCheckBoxChange} onSendInvite={this.onSendInvite}
        sentSessionRegistrationIds={this.state.sentSessionRegistrationIds}  />
    );
  }


  protected loadingContent(): JSX.Element | undefined {
    return (
      <div className="loading-layout">
        <Spinner label="Loading..." labelPosition="below" />
      </div>
    );
  }

  protected footerContent(): JSX.Element | undefined {
    const onCheckBoxChange = (checkedSessions:  LearnerSyllabusTask[]) => {     
      this.setState({checkedSessions:checkedSessions});
     }
     
    return (
      <SessionDialog isOpen={this.state.isClosed} sessions={this.state.liveSessions} onDismiss={this.onDismiss} checkedSessions={this.state.checkedSessions}
      onCheckBoxChange={onCheckBoxChange} onSendInvite={this.onSendInvite} sentSessionRegistrationIds={this.state.sentSessionRegistrationIds}/>
    )
  }

  private openDialog = () => {
    this.setState({
      isClosed: false
    });
  }

  private onDismiss = () => {
    this.setState({
      openDialog: true
    });
  };

  private mergeInviteStatus(liveSessionsData: LearnerSyllabusTask[], enrollmentLiveSessions: LiveSession[],liveSessionStatusData:ILiveSessionStatus): LearnerSyllabusTask[] {
      return liveSessionsData.map((task) => {        
        const matchingSession = enrollmentLiveSessions.find((session) => session.FlexTemplateItemId?.toLocaleLowerCase() === task.syllabusTask.flexTemplateItemId?.toLocaleLowerCase());
      if (matchingSession) {
        task.syllabusTask.name = matchingSession.title.substring(6).slice(0, -7); 
        task.inviteStatus=
        matchingSession.inviteStatus==="NotSent"  && liveSessionStatusData?.sessionRegistrationIds?.find(x=>x===task.sessionRegistrationId)!=null
        ?"Sent" 
        :matchingSession.inviteStatus;
      }
      return task;
    });
  }
}


export default LiveSessionWidget;


