import * as axios from "axios";

import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";

import { IApplicationConfig } from "../common/settings";
import { GetDefaultRequestConfig } from "../common/ServiceConfiguration";
import Logger from "../common/logger";
import { authentication, media } from "@microsoft/teams-js";
import { mediaAccessResult } from "../models";
import Helper from "../common/helper";


export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PUT = 'PUT',
    PATCH = 'PATCH'
  }




  export async function getMediaSASToken(mediaName: string, mediaResourceType: string = "1", appConfig: IApplicationConfig): Promise<mediaAccessResult> {
    const service = appConfig.services.apiServices;
    try {
      const token = await authentication.getAuthToken();

      const endpoint = Helper.format(service.endpoints.getMediaAccess as string, mediaName, mediaResourceType);
      // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
      const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
      );
  
      const requestConfig = await GetDefaultRequestConfig(service);
      const response = await Helper.assertGetRequest<mediaAccessResult>("getMediaSASToken()", endpoint, requestConfig, apiClient, undefined, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
      return response.data;
    } catch (err: unknown) {
      if (axios.default.isAxiosError(err)) {
        let apiErrorMsg = "";
  
        if (err?.response?.status === 404) {
          apiErrorMsg = `There was an error accessing the API. Please make sure you have deployed the API and it is running. ${service.baseUri}`;
        } else if (err.message === "Network Error") {
          apiErrorMsg =
            `Cannot call endpoint ${service.baseUri} due to network error, please check your network connection status and `;
        } else {
          apiErrorMsg = err.message;
          if (err.response?.data?.error) {
            apiErrorMsg += ": " + err.response.data.error;
          }
        }
  
        Logger.LogError(apiErrorMsg + "\n" + err);
        return {} as mediaAccessResult ;
      }
      Logger.LogError('', err);
      return {} as mediaAccessResult ;
    }
  }