import {
  makeStyles,
  shorthands,
  Text,
  Button,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuGroup,
  MenuGroupHeader,
  MenuItemRadio,
  MenuDivider,
  MenuItemCheckbox,
  MenuCheckedValueChangeEvent,
  MenuCheckedValueChangeData,
  MenuProps,
  MenuButton,
  PositioningImperativeRef,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import {
  AppsList24Regular,
  Flag24Regular,
  Video24Regular,
  Book24Regular,
  Beaker24Regular,
  ChartPerson24Regular,
  ChatBubblesQuestion24Regular,
  ShareScreenPersonOverlay24Regular,
  Filter24Regular,
  RadioButton24Regular,
  TaskListLtr24Regular,
  TasksApp24Regular,
  CheckboxChecked24Filled,
  RadioButton24Filled,
  CheckboxUnchecked24Regular,
} from "@fluentui/react-icons";

import "../styles/BlendedLearning.scss";
import "../styles/Syllabus.scss";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { FluentIcon } from "@fluentui/react-icons/lib/utils/createFluentIcon";
import clsx from "clsx";



const optionTypes = [
  {
    text: "All tasks",
    value: "Alltasks",
    icon: TasksApp24Regular,
    type: "Tasks",
  },
  {
    text: "Completed tasks",
    value: "Completedtasks",
    icon: TaskListLtr24Regular,
    type: "Tasks",
  },
  {
    text: "Remaining tasks",
    value: "Remainingtasks",
    icon: AppsList24Regular,
    type: "Tasks",
  },
  {
    text: "Flagged tasks",
    value: "Flaggedtasks",
    icon: Flag24Regular,
    type: "Flagged tasks",
  },
  {
    text: "Overview Video",
    value: "Videos",
    icon: Video24Regular,
    type: "Self Study",
  },
  {
    text: "Modules",
    value: "Modules",
    icon: Book24Regular,
    type: "Self Study",
  },
  {
    text: "Labs",
    value: "Labs",
    icon: Beaker24Regular,
    type: "Self Study",
  },
  {
    text: "Chalk Talks",
    value: "Chalk Talks",
    icon: ChartPerson24Regular,
    type: "Live Sessions",
  },
  {
    text: "Office Hours",
    value: "Office Hours",
    icon: ChatBubblesQuestion24Regular,
    type: "Live Sessions",
  },
  {
    text: "Lab Session",
    value: "Lab Sessions",
    icon: ShareScreenPersonOverlay24Regular,
    type: "Live Sessions",
  },
];


const useStyles = makeStyles({
  filterCol: {
    width: "70%",
    fontSize: "14px",
    fontWeight: "600",
    color: tokens.colorNeutralForeground1,
  },
  headerRow: {
    height: "32px",
    fontSize: "14px",
    fontWeight: "600",
    color: clsx(tokens.colorCompoundBrandBackgroundHover, "!important"),
    ...shorthands.padding("10px"),
  },
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: tokens.colorNeutralForeground1,
    ...shorthands.gap("2px"),
  },
  selectClearAll: {
    color: tokens.colorCompoundBrandForeground1Hover,
    float: "right",
    cursor: "pointer",
  },
  option: {
    display: "flex",
    alignItems: "center",
    ...shorthands.gap("15px"),
    width: "240px",
    paddingLeft: "0px !important",

  },
  text: {
    marginLeft: "-1em",
    ...shorthands.flex(1, 1, "auto")
  },
  iconColor: {
    color: tokens.colorCompoundBrandBackground,
  },
  icon: {
    ...shorthands.flex(0, 0, "auto"),
    marginLeft: "-1.5em",
    ":hover": {
      color: tokens.colorNeutralForeground1,
    }
  },
  syllbusFilterButton: {
    marginTop: ".5em !important",
    height: "42px", 
    fontSize: "16px !important",
    fontWeight: "400 !important",
    justifyContent: "left !important",
    paddingTop: "0",
    paddingRight: "0",
    paddingBottom: "0",
    paddingLeft: ".5em",
    color: tokens.colorCompoundBrandBackgroundHover,
  },
  unselected: {
    width: "130px",
    color: clsx(tokens.colorNeutralForeground1, "!important")
  },
  selected: {
    width: "190px",
    color: clsx(tokens.colorCompoundBrandBackgroundHover, "!important")
  }
});

interface IComponentProps {
  onSyllabusFilterChange: (selectedValue: string[]) => void;
  adjustPosition: boolean
}
export const SyllabusMenuFilter: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { onSyllabusFilterChange, adjustPosition } = props;
  const styles = useStyles();
  const filterRef = React.useRef(null);
  const groupTypes = ["Flagged tasks", "Self Study", "Live Sessions"];
  const [open, setOpen] = React.useState<boolean>(false);
  const [checkedValues, setcheckedValues] = React.useState<Record<string, string[]>>(
    { "radioButton_Filter": ["Alltasks"], "checkbox_Filter": [] });


  const adjustFilterPosition = () => {
    const filterRefrence = filterRef?.current as PositioningImperativeRef | null;
    filterRefrence?.updatePosition()
  }

  React.useEffect(() => {
    adjustFilterPosition();
  }, [adjustPosition]);

  const onClearAll = () => {
    ai.appInsights?.trackEvent({ name: "Syllabus Filter: Clear all" });
    onSyllabusFilterChange(["Alltasks"]);
    setcheckedValues({ "radioButton_Filter": ["Alltasks"], "checkbox_Filter": [] });
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter") {
      return onClearAll();
    }
  };
  const onCheckedValueChange = (e: MenuCheckedValueChangeEvent, data: MenuCheckedValueChangeData) => {
    ai.appInsights?.trackEvent({
      name:
        "Syllabus Filter: A menu option is selected: " +
        data.checkedItems,
    });
    let radiooptions: string[] = [];
    let checkboxoptions: string[] = [];
    if (data.name === "radioButton_Filter") {
      radiooptions = data.checkedItems;
      checkboxoptions = checkedValues["checkbox_Filter"];
    }
    else {
      radiooptions = checkedValues["radioButton_Filter"];
      checkboxoptions = data.checkedItems;
    };
    setcheckedValues({ "radioButton_Filter": radiooptions, "checkbox_Filter": checkboxoptions });
    onSyllabusFilterChange(radiooptions.concat(checkboxoptions));
  };
  const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
    setOpen(data.open);
  };
  const onClose = () => {
    setOpen(false);
  };
  const getIcon = (icon: FluentIcon) => {
    const Icon = icon;
    return <Icon className={styles.icon} />
  };
  return (
    <Menu persistOnItemClick hasIcons hasCheckmarks={false} onCheckedValueChange={onCheckedValueChange}
      open={open} onOpenChange={onOpenChange} checkedValues={checkedValues}
      closeOnScroll={true}
      positioning={{ position: "below", align: "start", pinned: true, positioningRef: filterRef }}
    >
      <MenuTrigger disableButtonEnhancement>
        <MenuButton appearance="subtle" icon={<Filter24Regular className={`${checkedValues["checkbox_Filter"].length > 0 ? styles.selected : styles.unselected
          }`} />} className={styles.syllbusFilterButton + ` ${checkedValues["checkbox_Filter"].length > 0 ? styles.selected : styles.unselected
            }`} aria-label={checkedValues["checkbox_Filter"].length > 0 ? `Filter (${checkedValues["checkbox_Filter"].length}) applied` : "Select task type"}>

          {`Filter ${checkedValues["checkbox_Filter"].length > 0 ? `(${checkedValues["checkbox_Filter"].length}) applied` : ''}`}

        </MenuButton>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <div className={styles.headerRow}    >
            <Text as="h2"
              className={styles.filterCol}
              aria-label="Filter box"
            >
              Filter
            </Text>
            <Text
              onClick={onClearAll}
              onKeyDownCapture={(event) => handleKeyDown(event)}
              className={styles.selectClearAll}
              aria-label="Clear all"
              tabIndex={0}
              role="button"
            >Clear all
            </Text>

          </div>
          <MenuDivider />
          <MenuGroup>
            <MenuGroupHeader aria-level={3} role="heading" className={styles.heading}>Tasks</MenuGroupHeader>
            {optionTypes.filter(x => x.type == "Tasks").map((task, index) => (
              <MenuItemRadio
                key={`task_${index}`}
                className={styles.option}
                icon={getIcon(task.icon)}
                secondaryContent={checkedValues["radioButton_Filter"][0] === task.value ? <RadioButton24Filled className={styles.iconColor} /> : <RadioButton24Regular />}
                name="radioButton_Filter"
                checkmark={''}
                value={task.value}>
                <Text className={styles.text}>{task.text}</Text>
              </MenuItemRadio>
            ))}
          </MenuGroup>
          <MenuDivider />
          {groupTypes.map((group, index) => (
            <>
              <MenuGroup key={`group_${index}`}>
                <MenuGroupHeader aria-level={3} role="heading" className={styles.heading}>{group}</MenuGroupHeader>
                {optionTypes.filter(x => x.type == group).map((option, optionIndex) => (
                  <MenuItemCheckbox
                    key={`checkbox_Filter${optionIndex}`}
                    className={styles.option}
                    name="checkbox_Filter"
                    icon={getIcon(option.icon)}
                    value={option.value}
                    secondaryContent={checkedValues["checkbox_Filter"].includes(option.value) ? <CheckboxChecked24Filled className={styles.iconColor} /> : <CheckboxUnchecked24Regular />}
                    checkmark={''}
                  > <Text className={styles.text}>{option.text}</Text>
                  </MenuItemCheckbox>
                ))}
              </MenuGroup>
              <MenuDivider />
            </>
          ))}
          <Button appearance="primary" className="closeBtn" onClick={onClose}>
            Close
          </Button>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
export default SyllabusMenuFilter;
