import { Text, makeStyles, shorthands } from "@fluentui/react-components";
import * as React from "react";

import { LearnerSyllabusTask } from "../../models";
import SessionTimer from "./SessionTimer";

import "../styles/Syllabus.scss";
import { convertToLocalTimeZone } from "../../common/dateUtils";

/**
 *ItemDetails component
 */
interface IComponentProps {
  item: LearnerSyllabusTask;
  isShowTimer: boolean;
  onContinue: () => void;
}

const useStyles = makeStyles({
  detailsWrapper: {
    display: "flex",
    ...shorthands.gap("0.5em"),
  },
});

export const ItemDetails: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { item, isShowTimer } = props;

  const classes = useStyles();

  return (
    <span className={classes.detailsWrapper}>
      <Text size={200}>
        {convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).toLocaleString("en-us", {
          weekday: "long",
          month: "long",
          day: "numeric",
        })}
      </Text>
      <Text size={200}>
        {convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).toLocaleString("en-us", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
        {" - "}
        {convertToLocalTimeZone(new Date(Date.parse(item.taskEndDateTime))).toLocaleString("en-us", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </Text>
      {isShowTimer && (
        <SessionTimer
          timerDurationInMinutes={
            (convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).getTime() - Date.now()) / 60 / 1000
          }
        />
      )}
    </span>
  );
};

export default ItemDetails;
