import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  Text,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { IntroDialogStep1 } from "./IntroDialogStep1";
import { IntroDialogStep2 } from "./IntroDialogStep2";
import { IntroDialogStep3 } from "./IntroDialogStep3";
import { IntroDialogStep4 } from "./IntroDialogStep4";
import Progress from "./Progress";
import { updateLearnerWelcomeTourState } from "../../services/blendedLearningService";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { ClarityHelper } from "../../internal/ClarityHelper";
import { ArrowLeft24Regular, ArrowRight24Regular } from "@fluentui/react-icons";

import "../styles/IntroDialog.scss";
import { BlendedLearningContext } from "../../internal/singletonContext";

/**
 * IntroDialog component
 */
interface IComponentProps {
  isOpen: boolean;
  onDismiss: () => void;
  subText?: string;
  textToDisplay?: string;
  textToDisplayContent?: string;
  learnerId: string;
  deliveryId: string;
  onWelcomeTourComplete: () => void;
}

const useStyles = makeStyles({
  dialogSurface: {
    minWidth: "88%",
    height: "36rem",
    paddingRight: "0",
    ...shorthands.borderRadius("0.5rem"),
  },
  dialogBody: {
    height: "100%",
  },
  dialogContent: {
    marginLeft: "2.5rem",
  },
  actions: {
    position: "relative",
    ...shorthands.gap("0.5em"),
  },
  dialogNextButton: {
    backgroundColor: tokens.colorBrandForegroundOnLightHover,
    color: "#fff",
    ...shorthands.gap("0.5em"),
  },
  dialogPreviousButton: {
    color: tokens.colorNeutralForeground2,
    ...shorthands.gap("0.5em"),
  },
});

export const IntroDialog: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { isOpen, onDismiss, deliveryId, learnerId, onWelcomeTourComplete } =
    props;
  const [stepNumber, setStepNumber] = useState(1);

  const isFetching = false; //useSelector<IApplicationState, boolean>(state => state.UserStore.isFetching, shallowEqual);

  const onBack = () => {
    ai.appInsights?.trackEvent({ name: "Intro Dialog: Previous dialog step." });
    ClarityHelper.setCustomTag("WelcomeDialogSteps", `Clicked Previous`);
    setStepNumber(stepNumber - 1);
  };

  const onContinue = () => {
    ai.appInsights?.trackEvent({ name: "Intro Dialog: Next dialog step." });
    ClarityHelper.setCustomTag("WelcomeDialogSteps", `Clicked Next`);
    setStepNumber(stepNumber + 1);
  };

  const onComplete = async () => {
    await updateLearnerWelcomeTourState(deliveryId, learnerId, BlendedLearningContext.getInstance().appConfig!);
    onWelcomeTourComplete();
    ai.appInsights?.trackEvent({
      name: "Intro Dialog: Dialog steps completed.",
    });
    setStepNumber(1);
    onDismiss();
  };

  const nextStepText = "Next";
  const finalStepText = "Done";
  const classes = useStyles();

  return (
    <Dialog open={isOpen}>
      <DialogSurface
        className={mergeClasses(classes.dialogSurface, "dialogSurfaceMobile")}
      >
        <DialogBody className={classes.dialogBody} aria-label="Welcome" aria-modal={true} role="dialog">
          <DialogContent className={classes.dialogContent}>
            {isFetching && <Progress />}
            {stepNumber === 1 && (
              <IntroDialogStep1 stepNumber={stepNumber} aria-label="Welcome" />
            )}
            {stepNumber === 2 && (
              <IntroDialogStep2
                stepNumber={stepNumber}
                aria-label="What to Expect"
              />
            )}
            {stepNumber === 3 && (
              <IntroDialogStep3
                stepNumber={stepNumber}
                aria-label="Live Sessions"
              />
            )}
            {stepNumber === 4 && (
              <IntroDialogStep4
                stepNumber={stepNumber}
                aria-label="Get Started!"
              />
            )}
            <DialogActions
              className={mergeClasses(classes.actions, "actionsMobile")}
            >
              <>
                {stepNumber === 1 && (
                  <Button
                    tabIndex={0}
                    onClick={onContinue}
                    appearance="subtle"
                    className={mergeClasses(
                      classes.dialogNextButton,
                      "singleButtonMobile"
                    )}
                    aria-label="Next"
                  >
                    <Text>{nextStepText}</Text>
                    <ArrowRight24Regular
                      aria-label="Arrow right icon"
                      className="icon"
                    />
                  </Button>
                )}
                {stepNumber >= 2 && stepNumber < 4 && (
                  <>
                    <Button
                      onClick={onBack}
                      appearance="subtle"
                      className={mergeClasses(
                        classes.dialogPreviousButton,
                        "customButton"
                      )}
                      aria-label="Previous"
                      tabIndex={0}
                    >
                      <ArrowLeft24Regular
                        className="icon"
                        aria-label="Arrow left icon"
                      />
                      Previous
                    </Button>
                    <Button
                      onClick={onContinue}
                      appearance="subtle"
                      className={mergeClasses(
                        classes.dialogNextButton,
                        "customButton"
                      )}
                      aria-label="Next"
                      tabIndex={0}
                    >
                      <Text>{nextStepText}</Text>
                      <ArrowRight24Regular
                        className="icon"
                        aria-label="Arrow right icon"
                      />
                    </Button>
                  </>
                )}
                {stepNumber === 4 && (
                  <>
                    <Button
                      onClick={onBack}
                      appearance="subtle"
                      className={mergeClasses(
                        classes.dialogPreviousButton,
                        "customButton"
                      )}
                      aria-label="Previous"
                      tabIndex={0}
                    >
                      <ArrowLeft24Regular
                        className="icon"
                        aria-label="Arrow left icon"
                      />
                      Previous
                    </Button>
                    <Button
                      onClick={onComplete}
                      appearance="subtle"
                      className={mergeClasses(
                        classes.dialogNextButton,
                        "customButton"
                      )}
                      aria-label="Done"
                      tabIndex={0}
                    >
                      <Text>{finalStepText}</Text>
                      <ArrowRight24Regular
                        className="icon"
                        aria-label="Arrow right icon"
                      />
                    </Button>
                  </>
                )}
              </>
            </DialogActions>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default IntroDialog;
