import * as axios from "axios";

import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";

import { IApplicationConfig } from "../common/settings";
import { PagedResponse } from "../models/pagedResponse";
import { GetDefaultRequestConfig} from "../common/ServiceConfiguration";
import { Delivery, IGetLiveSessionsResponse, LiveSession, IPatchEventSessionsRequest, IPatchEventSessionsResponse, IDeliveryTopic } from "../models";
import Logger from "../common/logger";
import {authentication } from "@microsoft/teams-js";
import { ILearnerTeamState } from "../models/learnerTeamState";
import Helper from "../common/helper";



function onErrorDeliveryService(err: unknown) {


    if (axios.default.isAxiosError(err)) {
        let apiErrorMsg = "";

        if (err?.response?.status === 404) {
            apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
        } else if (err.message === "Network Error") {
            apiErrorMsg =
                "Cannot call Azure Function due to network error, please check your network connection status and ";
            if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
            } else {
                apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
            }
        } else {
            apiErrorMsg = err.message;
            if (err.response?.data?.error) {
                apiErrorMsg += ": " + err.response.data.error;
            }
        }

        Logger.LogError(apiErrorMsg + "\n" + err);
        return undefined;
    }
    Logger.LogError('', err);
    return undefined;

}


export async function getDeliveryDetails(deliveryId: string, appConfig: IApplicationConfig): Promise<Delivery> {

    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return {} as Delivery;
    }
    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.Delivery as string, deliveryId);
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );

    let requestConfig = await GetDefaultRequestConfig(service);
    const response = await Helper.assertGetRequest<Delivery | undefined>("getDeliveryDetails()", endpoint, requestConfig, apiClient, onErrorDeliveryService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
    return response?.data ?? {} as Delivery;

}


export async function getDeliveryTopics(deliveryId: string, appConfig: IApplicationConfig) {


    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return undefined
    }
    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.Delivery as string, deliveryId);
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );

    const requestConfig = await GetDefaultRequestConfig(service);
    const response = await Helper.assertGetRequest<Delivery | undefined>("getDeliveryTopics()", endpoint, requestConfig, apiClient, onErrorDeliveryService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
    return response?.data;


}

export async function patchLiveSessions(params: IPatchEventSessionsRequest[], appConfig: IApplicationConfig): Promise<IPatchEventSessionsResponse> {


    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return {} as IPatchEventSessionsResponse
    }
    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.patchSessions as string, appConfig.enrollmentVersion);
    const usestaticBearerToken = false;
    const bearerToken = '';
    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );
    const axiosConfig = { headers: { 'Ocp-Apim-Subscription-Key': service.apiKey } }
    const response = await Helper.assertPatchRequest<IPatchEventSessionsResponse | undefined>("patchLiveSessions()", endpoint, params, axiosConfig, apiClient, onErrorDeliveryService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
    return response?.data ?? {} as IPatchEventSessionsResponse;

}

export async function getLiveSessions(deliveryId: string, learnerId: string, tpId: string, appConfig: IApplicationConfig): Promise<LiveSession[]> {

    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return [];
    }
    const service = appConfig.services.apiServices;


    const sessionEndpiont =  appConfig.featureFlags?.disableTpid?.isEnabledFlag == true ? service.endpoints.SessionsV2 : service.endpoints.Sessions;

    const endpoint = Helper.format( sessionEndpiont as string, appConfig.enrollmentVersion, deliveryId, learnerId, tpId);
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header

    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );

    let requestConfig = await GetDefaultRequestConfig(service);

    const response = await Helper.assertGetRequest<IGetLiveSessionsResponse | undefined>("getLiveSessions()", endpoint, requestConfig, apiClient, onErrorDeliveryService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);

    return response?.data?.sessions ?? [];


}



export async function getDeliveryTeamsId(groupId: string, appConfig: IApplicationConfig): Promise<Delivery> {


    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return {} as Delivery;
    }
    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.teamsSessions as string, groupId);

    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );

    let requestConfig = await GetDefaultRequestConfig(service);

    const response = await Helper.assertGetRequest<Delivery | undefined>("getDeliveryTeamsId()", endpoint, requestConfig, apiClient, onErrorDeliveryService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);

    return response?.data ?? {} as Delivery;

}


export async function getTopicsByDeliveryId(deliveryId: string, teamChannelId: string = "", appConfig: IApplicationConfig): Promise<IDeliveryTopic[]> {


    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return [];
    }
    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.DeliveryTopic as string) + deliveryId + "/topics" + (teamChannelId ? "?teamChannelId=" + teamChannelId : "");

    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );
    let requestConfig = await GetDefaultRequestConfig(service);

    const response = await Helper.assertGetRequest<PagedResponse<IDeliveryTopic> | undefined>("getTopicsByDeliveryId()", endpoint, requestConfig, apiClient, onErrorDeliveryService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
    return response?.data?.items ?? [];

}


export async function getLearnerTeamState(deliveryId: string, learnerId: string, appConfig: IApplicationConfig): Promise<ILearnerTeamState> {


    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return {} as ILearnerTeamState;;
    }
    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.getLearnerTeamState as string, learnerId, deliveryId);

    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );
    let requestConfig = await GetDefaultRequestConfig(service);
    const response = await Helper.assertGetRequest<ILearnerTeamState | undefined>("getLearnerTeamState()", endpoint, requestConfig, apiClient, onErrorDeliveryService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
    return response?.data ?? {} as ILearnerTeamState;

}


export async function saveLearnerTeamState(deliveryId: string, learnerId: string, state: ILearnerTeamState, appConfig: IApplicationConfig): Promise<ILearnerTeamState> {


    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return {} as ILearnerTeamState;;
    }
    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.saveLearnerTeamState as string, learnerId, deliveryId);

    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );
    let requestConfig = await GetDefaultRequestConfig(service);
    const response = await Helper.assertPutRequest<ILearnerTeamState | undefined>("saveLearnerTeamState()", endpoint, state, requestConfig, apiClient, onErrorDeliveryService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
    return response?.data ?? {} as ILearnerTeamState;

}
