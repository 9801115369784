import * as axios from "axios";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { IApplicationConfig } from "../common/settings";
import { GetDefaultRequestConfig } from "../common/ServiceConfiguration";
import Logger from "../common/logger";
import { authentication } from "@microsoft/teams-js";
import Helper from "../common/helper";


function onErrorSyllabusService(err: unknown) {


    if (axios.default.isAxiosError(err)) {
        let apiErrorMsg = "";

        if (err?.response?.status === 404) {
            apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
        } else if (err.message === "Network Error") {
            apiErrorMsg =
                "Cannot call Azure Function due to network error, please check your network connection status and ";
            if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
            } else {
                apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
            }
        } else {
            apiErrorMsg = err.message;
            if (err.response?.data?.error) {
                apiErrorMsg += ": " + err.response.data.error;
            }
        }

        Logger.LogError(apiErrorMsg + "\n" + err);
        return undefined;
    }
    Logger.LogError('', err);
    return undefined;

}


export async function updateLearnerSyllabusTaskCompletedState(id: string, userName: string, appConfig: IApplicationConfig) {
    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return undefined;
    }

    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.UpdateLearnerSyllabusTaskCompletedState as string, id, userName);

    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );

    const requestConfig = await GetDefaultRequestConfig(service);
    const response = await Helper.assertPatchRequest<any | undefined>("updateLearnerSyllabusTaskCompletedState()", endpoint, undefined, requestConfig, apiClient, onErrorSyllabusService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
    return response?.data;

}

export async function updateLearnerSyllabusTaskFlaggedState(id: string, userName: string, appConfig: IApplicationConfig) {

    let token = "";
    try {
        token = await authentication.getAuthToken();
    } catch (err: unknown) {

        Logger.LogError('', err);
        return undefined;
    }

    const service = appConfig.services.apiServices;
    const endpoint = Helper.format(service.endpoints.UpdateLearnerSyllabusTaskFlaggedState as string, id, userName)

    const apiClient = createApiClient(
        service.baseUri,
        new BearerTokenAuthProvider(async () => token)
    );

    const requestConfig = await GetDefaultRequestConfig(service);
    const response = await Helper.assertPatchRequest<any | undefined>("updateLearnerSyllabusTaskFlaggedState()", endpoint, undefined, requestConfig, apiClient, onErrorSyllabusService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);

    return response?.data;

}
