import {
  Dropdown,
  makeStyles,
  shorthands,
  useId,
  Option,
  tokens,
  DropdownProps,
  Text
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';

import '../styles/BlendedLearning.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getLearnerTeamState, getTopicsByDeliveryId, saveLearnerTeamState } from '../../services/deliveryService';
import { RibbonStar20Regular, Rocket20Regular } from '@fluentui/react-icons';
import { ILearnerTeamState } from '../../models/learnerTeamState';
import { IDeliveryTopic } from '../../models';
import { ai } from '../../internal/ApplicationInsightsProvider/ApplicationInsightsService';
import { ClarityHelper } from '../../internal/ClarityHelper';
import clsx from 'clsx';
import { BlendedLearningContext } from '../../internal/singletonContext';


/**
 * Syllabus component
 */

const useStyles = makeStyles({
  focusAreaContainer: {
    marginBottom: '1em',
    paddingTop: '1em',
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: 'repeat(1fr)',
    maxWidth: '40%',
  },
  focusAreaContainerSpan: {
    width: '100%',
    height: '100%',
    paddingRight: '1em',
  },
  listbox: {
    maxHeight: '400px',
    lineHeight: tokens.lineHeightBase600,
    backgroundColor: tokens.colorNeutralStroke3,
  },
  filledDarker: {
    backgroundColor: clsx(tokens.colorNeutralStroke3, '!important'),
  },
  focusAreaSelectedButton: {
    color: tokens.colorNeutralForeground1,
    backgroundColor: 'transparent !important',
  },

});
type FilterButtonProps = {
  text: string;
  weekNumber?: number;
};

const FilterButton = (props: FilterButtonProps) => {
  const { text, weekNumber } = props;
  const styles = useStyles();
  return (
    <div className={clsx(styles.focusAreaSelectedButton, 'focusAreaSelectedButton')} >
      {text != '' ? (
        <> {setWeekIcon(weekNumber)}
          <span >{text}</span></>
      ) : (
        <span>Select a focus area</span>
      )}
    </div>
  );
}

const setWeekIcon = (weekNumber?:number) => {
  return (
    <span className="dot">
      {(weekNumber == 0 &&
        <Rocket20Regular className="iconColor"></Rocket20Regular>
      )}
      {(weekNumber == 5 &&
        <RibbonStar20Regular className="iconColor"></RibbonStar20Regular>
      )}
      {((weekNumber != 0 && weekNumber != 5) &&
        <>{weekNumber}</>
      )}
    </span>
  );
}
interface IComponentProps extends DropdownProps {
  onFocusAreaChange: (selectedValue: string) => void;
  currentWeekNumber:number;
  isShowSelectedFousArea:boolean;
  channelId?:string;
  learnerId: string;
  deliveryId: string;
}
export const SyllabusFocusArea: React.FunctionComponent<IComponentProps> = props => {
  const { onFocusAreaChange,currentWeekNumber,isShowSelectedFousArea,channelId,deliveryId,learnerId } = props;
  const styles = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const [weekNumber, setWeekNumber] = React.useState<number>(currentWeekNumber);

  const appconfig = BlendedLearningContext.getInstance().appConfig;

  const { data: deliveryTopics, isLoading: isDeliveryTopicDataLoading } = useQuery({
    queryKey: ['DeliveryTopicData'],
    queryFn: () => getTopicsByDeliveryId(deliveryId, "", appconfig!),
  });
  const { data: learnerTeamSateData, isLoading: islearnerTeamStateLoading } = useQuery({
    queryKey: ["GetLearnerTeamState"],
    queryFn: () => getLearnerTeamState(deliveryId,learnerId, appconfig!),
  });

  const saveLearnerTeamStateMutation = useMutation({
    mutationFn: async (selectedFocusAreaId: any) => {
      await saveLearnerTeamState(deliveryId,learnerId,{selectedFocusAreaId:selectedFocusAreaId} as ILearnerTeamState, appconfig!)
    }
  });
  const options = deliveryTopics ? deliveryTopics.sort((a, b) => a.weekNumber - b.weekNumber) : [];
  const setFocusAreaValue=(currentTopic:IDeliveryTopic)=>{
     setValue(currentTopic.topics?.name);
      setWeekNumber(currentTopic.weekNumber);
      onFocusAreaChange(currentTopic.id);
  }

  const onOptionSelect: typeof props['onOptionSelect'] = (ev, data) => {
    ai.appInsights?.trackEvent({ name: `Focus area selected: ${data.optionText}` });
    ClarityHelper.setCustomTag('Focus area selected', `${data.optionText}`);
    setSelectedOptions(data.selectedOptions);
    setValue(data.optionText ?? '');
    let optionvalues = data.optionValue ? data.optionValue.split(":"):"";
    if(optionvalues!=null && optionvalues.length>0){
    onFocusAreaChange(optionvalues[0]);
    setWeekNumber(Number(optionvalues.length>1?optionvalues[1]:0 ?? 0));
    saveLearnerTeamStateMutation.mutate(optionvalues[0]);
    }
  };
  useEffect(() => {
    if(!isShowSelectedFousArea){
    if(!islearnerTeamStateLoading && learnerTeamSateData!=null 
      && learnerTeamSateData.selectedFocusAreaId!='00000000-0000-0000-0000-000000000000')
    {          
        let currentTopic=deliveryTopics?.filter((x)=>x.id==learnerTeamSateData.selectedFocusAreaId); 
        if(currentTopic && currentTopic?.length>0)
        {
          setFocusAreaValue(currentTopic[0]);   
        }       
    } 
    else
    {  
        let currentTopic=deliveryTopics?.filter(x=>x.weekNumber==currentWeekNumber); 
         if(currentTopic && currentTopic?.length>0)
         {
           setFocusAreaValue(currentTopic[0]);   
         }
    }}

   
  }, [deliveryTopics,learnerTeamSateData, islearnerTeamStateLoading]);

  useEffect(() => {
    if(isShowSelectedFousArea && channelId!=null && deliveryTopics!=null)
    {
      
        let currentTopic=deliveryTopics?.filter(x=>x.teamChannelId==channelId); 
         if(currentTopic && currentTopic?.length>0)
         {
           setFocusAreaValue(currentTopic[0]);   
         }
    }
   
  }, [deliveryTopics,channelId]);
  const dropdownId = useId('dropdown-controlled');

  return (
    <span className={styles.focusAreaContainer}>
      <span>
        <label htmlFor={dropdownId}>
          <Text className={styles.focusAreaContainerSpan}>Focus area</Text>
        </label>
      {/* </span> */}
        {(isShowSelectedFousArea?
        <Text className="showSelectedFocusArea">{weekNumber}-{value}</Text>
        :
        <Dropdown
          id={dropdownId}
          placeholder="Select a focus area"
          aria-labelledby={dropdownId}
          listbox={{ className: styles.listbox }}
          appearance="filled-darker"
          size="medium"
          value={value}
          as="button"
          button={<FilterButton weekNumber={weekNumber} text={value}></FilterButton>}
          selectedOptions={selectedOptions}
          onOptionSelect={onOptionSelect}
          aria-label="Select a focus area from this dropdown"
          className={styles.filledDarker}

          {...props}
        >
          {options.map((option) => (
              <Option key={option.id} value={`${option.id}:${option.weekNumber}`} text={option.topics.name} checkIcon={''} >
              {setWeekIcon(option.weekNumber)}
              <span  className="topicSize">{option.topics.name}</span>
            </Option>
          ))}
        </Dropdown>
        )}
      </span>
    </span>
  );
};

export default SyllabusFocusArea;
