import { ArrowUp16Regular } from "@fluentui/react-icons";
import React from "react";
import { CSSProperties } from "react";
import { Button } from "@fluentui/react-components";
import { widgetStyle } from "../lib/Widget.styles";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { ClarityHelper } from "../../internal/ClarityHelper";

interface IBackToTopProps {}

export class BackToTop extends React.Component<IBackToTopProps> {
  private handleClick = () => {
    ai.appInsights?.trackEvent({
      name: "Back to Top: Clicked on back to the top.",
    });
    ClarityHelper.setCustomTag("BackToTop", "Selected");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    const footerStyle: CSSProperties = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "fit-content",
      borderTop: "none",
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      paddingBottom: "0.1em",
      backgroundColor: "var(--colorNeutralBackground3)",
    };

    return (
      <div style={footerStyle}>
        <Button
          className={widgetStyle.footerBtn}
          onClick={this.handleClick}
          icon={<ArrowUp16Regular />}
          iconPosition="after"
          appearance="transparent"
          tabIndex={0}
        >
          Back to top
        </Button>
      </div>
    );
  }
}
