import React, { useCallback, useState } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import {
  Table,
  TableBody,
  TableCell,
  TableColumnDefinition,
  TableColumnId,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSelectionCell,
  Text,
  createTableColumn,
  makeStyles,
  useTableFeatures,
  useTableSelection,
  useTableSort,
} from "@fluentui/react-components";
import { liveSessionWidgetStyle } from "../lib/LiveSessionWidget.styles";
import SelectedSessions from "./SelectedSessions";
import SessionTimer from "./SessionTimer";
import SessionsSearch from "./SessionsSearch";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { convertToLocalTimeZone } from "../../common/dateUtils";

import "../styles/SessionsTable.scss";
import { ClarityHelper } from "../../internal/ClarityHelper";
import { useQueryClient } from "@tanstack/react-query";
import { LearnerSyllabusTask } from "../../models";
import JoinSessionButton from "./JoinSessionButton";
import { ILiveSessionStatus } from "../../models/liveSessionStatus";

export interface ISessionsTableProps {
  sessions: LearnerSyllabusTask[];
  selectAllSessionCheckboxLabel: string;
  // onCheckboxSelectAllChange: (event: any) => void;
  checkBoxlLabelText: string;
  onCheckBoxChange: (sessions: LearnerSyllabusTask[]) => void;
  dashboardWidget: boolean;
  onSendInvite?: (liveSessionStatus: ILiveSessionStatus) => void;
  sentSessionRegistrationIds:string[];
}

const useStyles = makeStyles({
  tableContent: {
    tableLayout: "auto",
  },
  sessionControls: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  tableHeader:{
    fontSize: "14px",
    fontWeight: "600"
  },
  sessionTitleStyle:{
    fontSize: "14px",
    fontWeight: "600"
  },
  dateTimeColumnStyle:{
    lineHeight:"1.5em"
  },
  rowStyle:{
    height:"53px"
  }
});

type Item = LearnerSyllabusTask;

const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "Datetime",
  }),
  createTableColumn<Item>({
    columnId: "divider",
  }),
  createTableColumn<Item>({
    columnId: "Sessiontitle",
  }),
  createTableColumn<Item>({
    columnId: "Invitationstatus",
  }),
];

const dateAndTimeStyle = {
  width: "6em",
};

const purpleDividerStyle = {
  width: "1em",
};

const sessionStyle = {
  width: "15em",
};

const dialogSessionStyle = {
  left: "-22%",
};

const invitationStyle = {
  width: "8em",
};

export const LiveSessionsTable: React.FunctionComponent<ISessionsTableProps> = (
  props
) => {

const queryClient = useQueryClient();

  const {
    sessions,
    dashboardWidget,
    selectAllSessionCheckboxLabel,
    checkBoxlLabelText,
    onCheckBoxChange,
    onSendInvite,
    sentSessionRegistrationIds
  } = props;
  const [sortState, setSortState] = React.useState<{
    sortDirection: "ascending" | "descending";
    sortColumn: TableColumnId | undefined;
  }>({
    sortDirection: "ascending" as const,
    sortColumn: "Datetime",
  });
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [sessionsData, setSessionsData] = useState(clone(sessions));
  const [selectedSessionsCount, setSelectedSessionsCount] = useState(
    sessionsData ? sessionsData?.filter((session) => session.checked).length : 0
  );
  const futureSessions = sessionsData?.filter(
    (session) => convertToLocalTimeZone(new Date(Date.parse(session.taskStartDateTime))).getTime() - Date.now() > 0
  );
  let count = sessionsData
    ? sessionsData?.filter((session) => session.checked).length
    : 0;

  const defaultMessage = "Sessions";

  const clearSearchSuggestions = () => {
    ai.appInsights?.trackEvent({
      name:
        "Live Sessions Table: Clear search suggestions: " + { showSuggestions },
    });
    setShowSuggestions(false);
  };

  const [items, setItemsState] = useState<Item[]>(sessionsData);
  // get table row id which is checked
  const getDefaultSelectedSessionDataRowIds=sessionsData?.map((e:LearnerSyllabusTask)=>e.checked?e.id:'').filter(x=>x!=='')??[];


  const {
    getRows,
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
  } = useTableFeatures<Item>(
    {
      columns,
      items,
      getRowId:(item)=>{return item.id;}
    },
    [
      useTableSort({
        sortState,
      }),
      useTableSelection({
        selectionMode: "multiselect",
        defaultSelectedItems:new Set(getDefaultSelectedSessionDataRowIds)
      }),
    ]
  );
  
  const rows = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    row.item.inviteStatus= (row.item.inviteStatus==="NotSent" && 
    sentSessionRegistrationIds.includes(row.item.sessionRegistrationId))
    ?"Sent":row.item.inviteStatus;
    return {
      ...row,
      onClick: (e: React.MouseEvent) => {
        toggleRow(e, row.rowId);
        row.item.checked = !row.item.checked;
        sessionsData?.filter((session) => session.id == row.item.id).forEach(x=>x.checked=row.item.checked);
        if (dashboardWidget) {
          onCheckBoxChange(sessionsData?.filter((session) => session.checked));
        }
        ai.appInsights?.trackEvent({
          name:
            "Live Sessions Table: Number of sessions selected (with mouse click): " +
            { selectedSessionsCount },
        });
        ClarityHelper.setCustomTag('SessionSelected', `${row.item.syllabusTask.name}`);
      },
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === " ") {
          e.preventDefault();
          toggleRow(e, row.rowId);
          row.item.checked = !row.item.checked;
          ai.appInsights?.trackEvent({
            name:
              "Live Sessions Table: Number of sessions selected (with key down): " +
              { selectedSessionsCount },
          });
          ClarityHelper.setCustomTag('SessionSelected', `${row.item.syllabusTask.name}`);
        }
      },
      selected,
      appearance: selected ? ("brand" as const) : ("none" as const),
      title: row.item.syllabusTask.name,
      className: "none",
    };
  });

  const onSessionTitleChange = (
    event?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    const sessionSearchDataQuery = sessionsData?.map((session) => ({
      ...session,
      isHidden: !session.syllabusTask.name
        .toLowerCase()
        .includes(newValue?.toLowerCase() ?? ""),
    }));

    setSessionsData(sessionSearchDataQuery);
    setItemsState(
      sessionSearchDataQuery.filter((session) => session.isHidden === false)
    );
    setShowSuggestions(true);

    if (newValue?.length === 0) {
      setShowSuggestions(false);
    }

    const isAvailableDiv = document.getElementById("isAvailable");
    isAvailableDiv?.removeAttribute("role");
    isAvailableDiv?.removeAttribute("aria-label");
    if (
      sessionsData?.length !==
      sessionSearchDataQuery?.filter((session) => session.isHidden === true)
        .length
    ) {
      isAvailableDiv?.setAttribute("role", "alert");
      isAvailableDiv?.setAttribute("aria-label", "Search results available");
    } else {
      isAvailableDiv?.setAttribute("role", "alert");
      isAvailableDiv?.setAttribute("aria-label", "No data available");
    }
    isAvailableDiv?.addEventListener("focusout", clearSearchSuggestions);
  };

  const toggleAllKeydown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === " ") {
        ai.appInsights?.trackEvent({
          name:
            "Live Sessions Table: All sessions selected (with key down): " +
            { selectedSessionsCount },
        });
        ClarityHelper.setCustomTag('AllSessionsSelected', `${selectedSessionsCount}`);
        toggleAllRows(e);
        UpdateCheckedCount();
        e.preventDefault();
      }
    },
    [toggleAllRows, allRowsSelected]
  );

  const toggleAllClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      ai.appInsights?.trackEvent({
        name:
          "Live Sessions Table: All sessions selected (with mouse click): " +
          { selectedSessionsCount },
      });
      ClarityHelper.setCustomTag('AllSessionsSelected', `${selectedSessionsCount}`);
      toggleAllRows(e);
      UpdateCheckedCount();
    },
    [toggleAllRows, allRowsSelected]
  );

  const UpdateCheckedCount = () => {
    if (!allRowsSelected) {
      sessionsData?.forEach((session) => {
        session.checked = true;
      });
      setSessionsData(sessionsData);
    } else {
      sessionsData?.forEach((session) => {
        session.checked = undefined;
      });
      setSessionsData(sessionsData);
    }
    if (dashboardWidget) {
      onCheckBoxChange(sessionsData?.filter((session) => session.checked));
    }
  };

  const joinSessionClickedTelemetry = (session: LearnerSyllabusTask) => {
    ai.appInsights?.trackEvent({ name: `LiveSessions: JoinSessionButtonClicked - ${session.syllabusTask.name}` });
    ClarityHelper.setCustomTag("LiveSessions: JoinSessionButtonClick", `${session.syllabusTask.name}`);
    
    
    queryClient.invalidateQueries(['UserTasksData']);
  };

  const classes = useStyles();
  const onSendInviteClick = (liveSessionStatus: ILiveSessionStatus) => {
    sessionsData.filter(x=>x.inviteStatus==="NotSent" && liveSessionStatus.sessionRegistrationIds.indexOf(x.sessionRegistrationId)>=0).forEach(z=>z.inviteStatus="Sent");
    items.filter(x=>x.inviteStatus==="NotSent" && liveSessionStatus.sessionRegistrationIds.indexOf(x.sessionRegistrationId)>=0).forEach(z=>z.inviteStatus="Sent");
    const newItems= clone(items);
    setSessionsData(sessionsData);
    setItemsState(newItems);
    if(onSendInvite){
     onSendInvite(liveSessionStatus);
    }
   }
  return (
    <>
      <div className="sessionsTableHeader">
        <div className={classes.sessionControls}>
          <SelectedSessions
            count={count}
            sessionsData={sessionsData}
            dashboardWidget={dashboardWidget}
            onSendInviteClick={onSendInviteClick}
          />
          <>
            {!dashboardWidget && (
              <SessionsSearch
                onSearchChange={onSessionTitleChange}
                sessions={sessionsData}
                showSuggestions={showSuggestions}
              />
            )}
          </>
        </div>
      </div>
      <Table size="small" className={classes.tableContent}>
        <TableHeader>
          <TableRow>
            <TableSelectionCell
              aria-label={selectAllSessionCheckboxLabel}
              width={50}
              checked={
                allRowsSelected ? true : someRowsSelected ? "mixed" : false
              }
              onClick={toggleAllClick}
              onKeyDown={toggleAllKeydown}
              checkboxIndicator={{ "aria-label": "Select all rows " }}
            />
            {dashboardWidget ? (
              <TableHeaderCell  style={dateAndTimeStyle} aria-describedby="Date and time" scope="col">
                <Text className={classes.tableHeader} >&nbsp;Date & time&nbsp;</Text>
              </TableHeaderCell>
            ) : (
              <TableHeaderCell aria-labelledby="Session title" scope="col"><Text className={classes.tableHeader}>Session title</Text></TableHeaderCell>
            )}
            {dashboardWidget ? (
              <TableHeaderCell className={classes.tableHeader} style={purpleDividerStyle} aria-label="Purple divider"></TableHeaderCell>
            ) : (
              <TableHeaderCell aria-labelledby="Session date" scope="col"><Text className={classes.tableHeader} >Session date</Text></TableHeaderCell>
            )}
            {dashboardWidget ? (
              <TableHeaderCell className={classes.tableHeader} style={sessionStyle} aria-labelledby="Session title" scope="col">
               <Text className={classes.tableHeader}  aria-hidden={false}> Session title</Text>
              </TableHeaderCell>
            ) : (
              <TableHeaderCell aria-labelledby="Session time" scope="col"><Text className={classes.tableHeader} >Session time</Text></TableHeaderCell>
            )}
            {dashboardWidget ? (
              <TableHeaderCell  style={invitationStyle} aria-labelledby="Invitation status" scope="col">
               <Text className={classes.tableHeader}>Invitation status</Text> 
              </TableHeaderCell>
            ) : (
              <TableHeaderCell aria-labelledby="Invitation status" scope="col"> 
              <Text className={classes.tableHeader}>Invitation status</Text> </TableHeaderCell>
            )}
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.map(
            ({ item: session, selected, onClick, onKeyDown, appearance }) => (
              <TableRow
                key={session.id}
                onClick={onClick}
                onKeyDown={onKeyDown}
                aria-selected={selected}
                appearance={appearance}
                className={dashboardWidget?classes.rowStyle:""}
              >
                <TableSelectionCell
                  checked={selected}
                  checkboxIndicator={{ "aria-label": "Select row" }}
                />
                <TableCell>
                  {dashboardWidget ? (
                    <>
                      <Text className={classes.dateTimeColumnStyle}>
                        {
                        convertToLocalTimeZone(new Date(Date.parse(session.taskStartDateTime))).toLocaleString(
                          "en-us",
                          { month: "long", day: "numeric", year: "numeric" }
                        )
                        }
                      </Text>
                      <br />
                      <Text size={200} className={classes.dateTimeColumnStyle}>
                        {convertToLocalTimeZone(new Date(Date.parse(session.taskStartDateTime))).toLocaleString(
                          "en-us",
                          { hour: "numeric", minute: "numeric", hour12: true }
                        )}
                        -
                        {convertToLocalTimeZone(new Date(Date.parse(session.taskEndDateTime))).toLocaleString(
                          "en-us",
                          { hour: "numeric", minute: "numeric", hour12: true }
                        )}
                      </Text>
                    </>
                  ) : (
                    <Text size={200}>
                      {session.syllabusTask.name}
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  {dashboardWidget ? (
                    <div
                      className={liveSessionWidgetStyle.bodyDividerCell}
                      aria-hidden={true}
                    ></div>
                  ) : (
                    <Text aria-hidden={false}>
                      {convertToLocalTimeZone(new Date(Date.parse(session.taskStartDateTime))).toLocaleString(
                        "en-us",
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          hour12: true,
                        }
                      )}
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  {dashboardWidget ? (
                    <Text className={classes.sessionTitleStyle}>
                      {session.syllabusTask.name}
                      <SessionTimer
                        timerDurationInMinutes={
                          (convertToLocalTimeZone(new Date(Date.parse(session.taskStartDateTime))).getTime() - Date.now()) /
                          60 /
                          1000
                        }
                      />
                    </Text>
                  ) : (
                    <>
                      <Text>
                        {convertToLocalTimeZone(new Date(Date.parse(session.taskStartDateTime))).toLocaleString(
                          "en-us",
                          { hour: "numeric", minute: "numeric", hour12: true }
                        )}{" "}
                        to{" "}
                        {convertToLocalTimeZone(new Date(Date.parse(session.taskEndDateTime))).toLocaleString(
                          "en-us",
                          { hour: "numeric", minute: "numeric", hour12: true }
                        )}
                      </Text>
                    </>
                  )}
                </TableCell>
                <TableCell
                  className={liveSessionWidgetStyle.bodyInvitationStatus}
                >
                  {dashboardWidget &&
                  (convertToLocalTimeZone(new Date(Date.parse(session.taskStartDateTime))).getTime() - Date.now()) / 60 / 1000 <
                    30 ? (
                    <JoinSessionButton item={session} />
                  ) : session.inviteStatus === "NotSent" ? (
                    <Text className={liveSessionWidgetStyle.bodyNotSentStatus}>
                      Not Sent
                    </Text>
                  ) : (
                    <Text className={liveSessionWidgetStyle.bodySentStatus}>
                      Sent
                    </Text>
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </>
  );
};
function clone(liveSessions: any): LearnerSyllabusTask[] {
  return liveSessions ? JSON.parse(JSON.stringify(liveSessions)) : [];
}
